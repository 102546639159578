@import 'footerV2';

/*=== 5.1 BANNER ===*/
.bannercontainerV2 .tp-caption.rs-caption-1,
.bannercontainerV2 .tp-caption.rs-caption-2,
.bannercontainerV2 .tp-caption.rs-caption-3{
	width: 90%;
	@include mobile {
		width: 70%;
	}
	@include tablet {
		width: 60%;
	}
	@include large-desktops {
		width: 50%;
	}
}
.bannercontainerV2{
	.tp-caption.rs-caption-1{
		color: $white-color;
		br{
			@include mobile-all {
				display: none;
			}
			@include desktops{
				display: block;
			}
		}
		line-height: 55px !important;
		span{
			@include mobile-all {
				color: $color7 !important;
			}
		}
	}
	.tp-caption.rs-caption-2{
		color: $white-color;
		@include mobile-all {
			font-size: 14px !important;
		}
		@include tablet{
			font-size: 20px !important;
		}

	}
	.tp-caption.rs-caption-3{
		.btn-default{
			@include mobile-all {
				padding: 6px 12px !important;
				font-size: 14px;
				background-color: $white-color !important;
				color: $color8 !important;
				font-weight: 900;
			}
			@include tablet{
				font-size: 16px;
				padding: 11px 50px !important;
			}
			@include desktops{
				font-size: 18px;
				// padding: 13px 60px !important;
			}

		}
	}

}




/*=== 5.2.BANNER BOTTOM ===*/
.banner-bottom{
	width: 100%;
    float: left;
}
.banner-bottom-inner{
	position:relative;
	z-index:500;
	width: 100%;
    float: left;
    @include mobile {
		top:-30px;
	}
    @include tablet {
		top:-90px;
	}
	.colContent{
		position:relative;
		img{
			@include border-radius(9px);
			@include box-shadow(0, 4px, 0, rgba($black-color, .1) );
			display:block;
		}
	}
	.colContent-info{
		position:absolute;
		h3{
			font-family: 'Dosis', sans-serif;
		    font-size: 20px;
		    color: $white-color;
		    text-transform: capitalize;
		    line-height: 34px;
		    padding:0 10px;
		    display: inline-block;
		    margin-bottom: 5px;
		    @include desktops {
				font-size: 30px;
				line-height: 44px;
		    	padding:0 25px;
			}
		}
		p{
			font-size: 12px;
			color: $text-color;
			margin: 0;
			padding: 7px 10px;
			width: 90%;
			background: #f2f2f2;
			@include desktops {
				font-size: 14px;
				width: 75%;
		    	padding: 7px 25px;
			}
		}
	}
	.colContent-info.text-left{
		top: 25px;
		@include desktops {
			top: 55px;
		}
		h3, p{
		    border-radius:0 6px 6px 0;
		}
	}
	.colContent-info.text-center{
		bottom: 0;
		left: 20%;
		h3{
		    border-radius:6px;
		}
		p{
			margin: 0 auto;
			border-radius:6px 6px 0 0;
		}
	}
	.colContent-info.text-right{
		right: 0;
		top: 25px;
		@include desktops {
			top: 55px;
		}
		h3, p{
		    border-radius:6px 0 0 6px;
		}
		p{
			margin-right: 0;
		    margin-left: auto;
		}
	}
}

/*=== 5.3 PRODUCT SECTION ===*/
.promotionWrapper{
	.promotionInfo{
		.btn-default{
			background-color: $white-color;
			color: $color3 !important;
			padding: 13px 28px !important;
			font-weight: 700;
			> i {
		    margin-right: 10px;
		    padding-left: 7px;

			}
		}
	}
}

/*=== 5.3 PRODUCT SECTION ===*/
.productSection .col-xs-6{
	@include mobile-xs {
		width: 100%;
	}
}
.box{
	@include border-radius(12px);
	@include box-shadow(0, 4px, 0, rgba($black-color, .1) );
	margin-bottom:30px;
}
.box-img{
	border-radius:12px 12px 0 0;
	border:4px solid transparent;
	background:$white-color;
	padding:30px 0;
	position:relative;
	img{
		border-radius:12px 12px 0 0;
		display: inline-block;
		margin: 0 auto;
		max-height: 132px;
	}
	.btn-block{
		position:absolute;
		bottom:0;
		height: 55px;
		line-height: 55px;
		color: $white-color;
		background:$color3;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		@include transition(all 0.3s ease-in-out);
		@include transform (translate(0%, 50%));
		z-index: -1;
		i{
			margin-right: 7px;
		}
	}
}
.box:hover .box-img .btn-block{
	@include transform (translate(0%, 0%));
	@include transition(all 0.3s ease-in-out);
	z-index:1;
}
.box-info{
	padding:30px;
	h4{
		font-family: 'Dosis', sans-serif;
	    font-size: 20px;
	    color: $white-color;
	    text-transform: capitalize;
	    margin-bottom: 15px;
	    font-weight: 700;
	    a{
	    	color: $white-color;
	    }
	}
	.btn-primary{
		font-size: 14px;
	    background-color: $white-color;
	    color: $text-color;
    	font-weight: 600;
    	&:hover{
    		background-color: $white-color;
    	}
	}
}
.price span{
	font-size: 27px;
    color: $white-color;
    text-transform: capitalize;
    margin-bottom: 15px;
    font-weight: 600;
    display: inline-block;
    del{
    	color: rgba($white-color,0.7);
    }
}
.box .sticker-round {
    width: auto;
    padding: 10px;
    left: 7px;
    top: 7px;
    font-size: 13px;
}
.productSection .tabCommon .tab-pane {
    border:0;
    border-radius: 0;
}

/*=== 5.4 BRAND SECTION ===*/
.brandSection{
	margin-bottom:90px;
	.owl-carousel .owl-item{
		height: 150px;
	}
	.partnersLogoSlider{
		.slide{
			display: table;
			width: 200px;
			margin: 0 auto;
			height: 145px;
			background:#f8f8f8;
			@include box-shadow(0, 4px, 0, rgba($black-color, .1) );
			@include border-radius(9px);
			@include tablet {
				width: 100%;
			}
			.partnersLogo{
				display: table-cell;
				vertical-align: middle;
				text-align:center !important;
				width: 200px;
				img{
					width: auto;
					margin: 0 auto;
				}
			}
		}
	}
	.owl-nav {
	    @include tablet {
			display: none;
		}
	}
}
