* {
    outline: 0;
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $text-color;
    font-weight: 400;
}
ul{
	margin:0;
	padding:0;
	li{
		list-style-type:none;
	}
}
p{
    font-size: 14px;
    color: $text-color;
    font-weight: 400;
    line-height: 21px;
}
h1, h2, h3, h4, h5{
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: $secondary-color;
    margin-top: 0;
	font-weight: 700;
}
h4{
   font-size: 18px;
   margin-bottom: 25px;
   font-weight: 400;  
}
h5{
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
}
.form-control{
    box-shadow: none;
}