/* ====== index =====
1. COMMON
2. UI KIT
	2.1 BUTTON
	2.2 SECTION TITLE
	2.3 FORM
	2.4 PAGINATION
	2.5 ACCORDION
	2.6 TABS
	2.7 DATEPICKER
	2.8 THUMBNAIL
	2.9 MODAL
	2.10 PANEL
	2.11 PRICE RANGE SLIDER
	2.12 LIST
	2.13 CART
	2.14 PAGER
	2.15 Extra
  2.16 ALERT
  2.17 LABELS
  2.18 BADGES
  2.19 PROGRESS BAR
  2.20 LIST GROUP
  2.21 PANEL
  2.22 JAMBOTRON
  2.23 TOOLTIP



3. 1.1 HEADER
	3.1 TOP COLOR BAR
	3.2 TOP INFO BAR
	3.3 HEADER TRANSPARENT
	3.4 LIGHT HEADER
   1.2 HEADER V3
4. 1.1 FOOTER
	4.1 FOOTER INFO
	4.2 COPY RIGHT
   1.2 FOOTER V2
5. 1.1 HOME
	5.1 BANNER
	5.2 FEATURES SECTION
	5.3 PROMOTION
	5.4 ABOUT SCHOOL
	5.5 SERVICES SECTION
	5.7 TEAM SECTION
	5.8 EVENT SECTION
	5.9 COUNT UP SECTION
	5.10 HOME GALLERY SECTION
	5.11 COMMENT SECTION
	5.12 MAP AREA SECTION
	5.13 HOME CONTACT SECTION
   1.2 HOME V2
    5.1 BANNER
    5.2.BANNER BOTTOM
    5.3 PRODUCT SECTION
    5.4 BRAND SECTION
   1.2 HOME V3
    5.1 BANNER
6. 6. COURSE
	6.1 COURSE LEFT SIDEBAR
	6.2 COURSE LIST
	6.3 COURSE RIGHT SIDE BAR
	6.4 COURSE COUNTER
7. BLOG
8. COMING SOON
9. SERVICES
	9.1 SERVICES STYLE
10. TESTIMONIAL
11. CART PAGE
12. PRICING TABLE
13. CHECKOUT STEP
	13.1 CHECKOUT PROGRESS
	13.2 CHECKOUT INFO
14. SINGLE PRODUCT
    14.1 PRODUCT GALLERY
    14.2 PRODUCT REVIEW
15. CATEGORY
===================== */
/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*=== MEDIA QUERY ===*/
* {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  font-weight: 400;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

p {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  line-height: 21px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  color: #202020;
  margin-top: 0;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: 400;
}

h5 {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

.form-control {
  box-shadow: none;
}

/*=== 1.COMMON ===*/
.container {
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    width: 450px;
  }
}

@media (min-width: 320px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.main-wrapper {
  background-color: #ffffff;
}

body.bodyColor.default {
  background-color: #f5f5f5;
}

body.bodyColor.pattern-01 {
  background-image: url("../img/patterns/001.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-02 {
  background-image: url("../img/patterns/002.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-03 {
  background-image: url("../img/patterns/003.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-04 {
  background-image: url("../img/patterns/004.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-06 {
  background-image: url("../img/patterns/006.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-07 {
  background-image: url("../img/patterns/007.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.pattern-05 {
  background-image: url("../img/patterns/005.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

body.bodyColor.wrapper {
  margin: 0 auto;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 480px) {
  body.bodyColor.wrapper {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  body.bodyColor.wrapper {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor.wrapper {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor.wrapper {
    max-width: 1170px;
  }
}

@media (min-width: 480px) {
  body.bodyColor .header-wrapper {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  body.bodyColor .header-wrapper {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor .header-wrapper {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor .header-wrapper {
    max-width: 1170px;
  }
}

@media (min-width: 480px) {
  body.bodyColor .color-bar {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  body.bodyColor .color-bar {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor .color-bar {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor .color-bar {
    max-width: 1170px;
  }
}

body.bodyColor #menuBar.bb-fixed-header {
  right: auto;
  left: auto;
}

@media (min-width: 480px) {
  body.bodyColor #menuBar.bb-fixed-header {
    max-width: 450px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  body.bodyColor #menuBar.bb-fixed-header {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor #menuBar.bb-fixed-header {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor #menuBar.bb-fixed-header {
    max-width: 1170px;
  }
}

body.static #menuBar.bb-fixed-header {
  display: none;
}

.btn {
  display: inline-block;
  padding: 11px 20px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  color: #ffffff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  text-transform: uppercase;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  border: 1px solid transparent;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3) !important;
}

.btn:focus, .btn.active, .btn:active {
  border: 1px solid transparent;
  outline: 0;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
}

a {
  color: #ea7066;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  outline: 0;
}

a:focus {
  outline: 0;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

.form-control:focus {
  box-shadow: none;
}

.color-1 {
  color: #f0c24b !important;
}

.bg-color-1 {
  background-color: #f0c24b !important;
}

.border-color-1 {
  border-color: #f0c24b !important;
}

.color-2 {
  color: #b5d56a !important;
}

.bg-color-2 {
  background-color: #b5d56a !important;
}

.border-color-2 {
  border-color: #b5d56a !important;
}

.color-3 {
  color: #ea7066 !important;
}

.bg-color-3 {
  background-color: #ea7066 !important;
}

.border-color-3 {
  border-color: #ea7066 !important;
}

.color-4 {
  color: #84bed6 !important;
}

.bg-color-4 {
  background-color: #84bed6 !important;
}

.border-color-4 {
  border-color: #84bed6 !important;
}

.color-5 {
  color: #a597e7 !important;
}

.bg-color-5 {
  background-color: #a597e7 !important;
}

.border-color-5 {
  border-color: #a597e7 !important;
}

.color-6 {
  color: #ea77ad !important;
}

.bg-color-6 {
  background-color: #ea77ad !important;
}

.border-color-6 {
  border-color: #ea77ad !important;
}

.bg-color-7 {
  background-color: #2f3c43;
}

.full-width {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.p-n {
  padding: 0;
}

.p-t-n {
  padding-top: 0 !important;
}

.table-curved {
  border-collapse: separate;
  border: solid #ccc 1px;
  border-radius: 6px;
  border-left: 0px;
  border-top: 0px;
}

.table-curved > thead:first-child > tr:first-child > th {
  border-bottom: 0px;
  border-top: 0;
}

.table-curved > thead:first-child > tr > th:first-child {
  border-left: 1px solid #f0c24b;
}

.table-curved > thead:first-child > tr > th:last-child {
  border-right: 1px solid #ea77ad;
}

.table-curved td, .table-curved th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.table-curved > :first-child > :first-child > :first-child {
  border-radius: 6px 0 0 0;
}

.table-curved > :first-child > :first-child > :last-child {
  border-radius: 0 6px 0 0;
}

.table-curved > :last-child > :last-child > :first-child {
  border-radius: 0 0 0 6px;
}

.table-curved > :last-child > :last-child > :last-child {
  border-radius: 0 0 6px 0;
}

/*=== 2. UI KIT ===*/
.margin-top {
  margin-top: 40px;
}

.margin-bottom {
  margin-bottom: 40px;
}

.space-25 {
  margin-bottom: 25px;
}

.space-50 {
  margin-bottom: 50px;
}

.not-rounded {
  border-radius: 0 !important;
}

aside {
  margin-bottom: 25px;
}

/*=== 2.1 BUTTON ===*/
.btn-xl {
  font-size: 20px;
  padding: 9px 10px;
}

.btn-lg {
  font-size: 15px;
  padding: 10px 12px !important;
}

.btn-sm {
  font-size: 12px;
  padding: 7px 6px !important;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 8px !important;
}

.btn-rounded {
  border-radius: 5px !important;
}

.btn-pill {
  border-radius: 50px !important;
}

.btn-circle {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  padding: 3px !important;
}

.btn-square {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  padding: 3px !important;
}

.btn-link {
  color: #9875ab;
}

.btn-link:hover {
  color: #7c618a;
  text-decoration: none;
}

.btn-link:focus {
  color: #7c618a;
  text-decoration: none;
}

.btn-primary {
  background-color: #ea7066 !important;
  border: 2px solid #ea7066 !important;
  color: #ffffff;
}

.btn-primary > i {
  margin-right: 10px;
  padding-left: 7px;
}

.btn-square {
  border-radius: 0 !important;
}

.btn-outline-success {
  color: #5cb85c;
  background-color: transparent;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-info {
  color: #5bc0de;
  background-color: transparent;
  border-color: #5bc0de;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-warning {
  color: #f0ad4e;
  background-color: transparent;
  border-color: #f0ad4e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-primary {
  color: #ea7066;
  background-color: transparent;
  border-color: #ea7066;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #ea7066;
  border-color: #ea7066;
}

.btn span {
  margin-left: 10px;
  margin-right: 10px;
}

.box-info .btn-primary {
  background-color: #ffffff !important;
  border: none !important;
}

#buttons .btn {
  margin: 5px 9px;
  text-transform: capitalize;
}

#buttons .btn-social {
  margin: 5px 9px;
}

/* Social Buttons */
.btn-social {
  font-size: 13px;
  padding: 8px 15px;
  letter-spacing: 0.5px;
  vertical-align: middle;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-social span {
  margin-left: 10px;
}

/* Facebook Button */
.btn-facebook, .btn-facebook:active, .btn-facebook:focus {
  color: #4e68a1;
  background: transparent;
  border: 2px solid #4e68a1;
}

.btn-facebook:hover {
  color: white !important;
  background: #4e68a1;
  border: 2px solid #4e68a1;
}

.btn-facebook-filled, .btn-facebook-filled:active, .btn-facebook-filled:focus {
  color: white;
  background: #4e68a1;
  border: 2px solid #4e68a1;
}

.btn-facebook-filled:hover {
  color: white;
  background: #3b4f7a;
  border: 2px solid #3b4f7a;
}

.btn-facebook-link, .btn-facebook-link:active, .btn-facebook-link:focus {
  color: #4e68a1;
  background: transparent;
  border: none;
}

.btn-facebook-link:hover {
  color: #3b4f7a;
}

/* Twitter Button */
.btn-twitter, .btn-twitter:active, .btn-twitter:focus {
  color: #65b5f2;
  background: transparent;
  border: 2px solid #65b5f2;
}

.btn-twitter:hover {
  color: white;
  background: #65b5f2;
  border: 2px solid #65b5f2;
}

.btn-twitter-filled, .btn-twitter-filled:active, .btn-twitter-filled:focus {
  color: white;
  background: #65b5f2;
  border: 2px solid #65b5f2;
}

.btn-twitter-filled:hover {
  color: white;
  background: #5294c6;
  border: 2px solid #5294c6;
}

.btn-twitter-filled:hover {
  color: white;
  background: #5294c6;
  border: 2px solid #5294c6;
}

.btn-twitter-link, .btn-twitter-link:active, .btn-twitter-link:focus {
  color: #65b5f2;
  background: transparent;
  border: none;
}

.btn-twitter-link:hover {
  color: #5294c6;
}

/* Google Button */
.btn-google, .btn-google:active, .btn-google:focus {
  color: #e05d4b;
  background: transparent;
  border: 2px solid #e05d4b;
}

.btn-google:hover {
  color: white;
  background: #e05d4b;
  border: 2px solid #e05d4b;
}

.btn-google-filled, .btn-google-filled:active, .btn-google-filled:focus {
  color: white;
  background: #e05d4b;
  border: 2px solid #e05d4b;
}

.btn-google-filled:hover {
  color: white;
  background: #b94c3d;
  border: 2px solid #b94c3d;
}

.btn-google-link, .btn-google-link:active, .btn-google-link:focus {
  color: #e05d4b;
  background: transparent;
  border: none;
}

.btn-google-link:hover {
  color: #b94c3d;
}

/* Linkedin Button */
.btn-linkedin, .btn-linkedin:active, .btn-linkedin:focus {
  color: #2083bc;
  background: transparent;
  border: 2px solid #2083bc;
}

.btn-linkedin:hover {
  color: white;
  background: #2083bc;
  border: 2px solid #2083bc;
}

.btn-linkedin-filled, .btn-linkedin-filled:active, .btn-linkedin-filled:focus {
  color: white;
  background: #2083bc;
  border: 2px solid #2083bc;
}

.btn-linkedin-filled:hover {
  color: white;
  background: #186592;
  border: 2px solid #186592;
}

.btn-linkedin-link, .btn-linkedin-link:active, .btn-linkedin-link:focus {
  color: #2083bc;
  background: transparent;
  border: none;
}

.btn-linkedin-link:hover {
  color: #186592;
}

/* Pinterest Button */
.btn-pinterest, .btn-pinterest:active, .btn-pinterest:focus {
  color: #d2373b;
  background: transparent;
  border: 2px solid #d2373b;
}

.btn-pinterest:hover {
  color: white;
  background: #d2373b;
  border: 2px solid #d2373b;
}

.btn-pinterest-filled, .btn-pinterest-filled:active, .btn-pinterest-filled:focus {
  color: white;
  background: #d2373b;
  border: 2px solid #d2373b;
}

.btn-pinterest-filled:hover {
  color: white;
  background: #ad2c2f;
  border: 2px solid #ad2c2f;
}

.btn-pinterest-link, .btn-pinterest-link:active, .btn-pinterest-link:focus {
  color: #d2373b;
  background: transparent;
  border: none;
}

.btn-pinterest-link:hover {
  color: #ad2c2f;
}

/* Dribbble Button */
.btn-dribbble, .btn-dribbble:active, .btn-dribbble:focus {
  color: #ec5f94;
  background: transparent;
  border: 2px solid #ec5f94;
}

.btn-dribbble:hover {
  color: white;
  background: #ec5f94;
  border: 2px solid #ec5f94;
}

.btn-dribbble-filled, .btn-dribbble-filled:active, .btn-dribbble-filled:focus {
  color: white;
  background: #ec5f94;
  border: 2px solid #ec5f94;
}

.btn-dribbble-filled:hover {
  color: white;
  background: #b4446e;
  border: 2px solid #b4446e;
}

.btn-dribbble-link, .btn-dribbble-link:active, .btn-dribbble-link:focus {
  color: #ec5f94;
  background: transparent;
  border: none;
}

.btn-dribbble-link:hover {
  color: #b4446e;
}

.backToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 45px;
  background-color: #ea7066;
  border: 2px solid #ffffff;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  color: #ffffff;
  display: block;
  font-size: 16px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.backToTop:hover, .backToTop:focus, .backToTop:active {
  color: #ffffff;
}

/*=== 2.2 SECTION TITLE ===*/
@media (min-width: 320px) {
  .whiteSection, .mainContent, .lightSection, .colorSection {
    padding: 50px 0;
  }
}

@media (min-width: 768px) {
  .whiteSection, .mainContent, .lightSection, .colorSection {
    padding: 100px 0;
  }
}

.whiteSection, .mainContent {
  background-color: #ffffff;
}

.lightSection {
  background-color: #f5f5f5;
}

.sectionTitle h2 {
  font-family: 'Dosis', sans-serif;
  font-size: 36px;
  color: #ea7066;
  text-transform: capitalize;
  float: left;
  width: 100%;
}

.sectionTitle h2 span {
  padding: 0 15px;
}

@media (min-width: 320px) {
  .sectionTitle h2 span {
    display: block;
    width: 100%;
    float: left;
  }
}

@media (min-width: 768px) {
  .sectionTitle h2 span {
    display: inline-block;
    width: auto;
    float: none;
  }
}

@media (min-width: 320px) {
  .sectionTitle h2 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .sectionTitle h2 {
    margin-bottom: 70px;
  }
}

.sectionTitle.alt h2 {
  color: #ffffff;
}

.sectionTitleSmall {
  margin-bottom: 40px;
}

.sectionTitleSmall h2 {
  text-align: center;
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
  margin: 0 0 12px 0;
  color: #ffffff;
}

.sectionTitleSmall p {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
}

.pageTitleSection {
  background-image: url("../img/page-title/page-title-bg.jpg");
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  padding: 75px 0;
}

.pageTitleSection .pageTitleInfo {
  text-align: center;
}

.pageTitleSection .pageTitleInfo h2 {
  font-size: 40px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 40px;
  margin: 0 0 8px 0;
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
  font-weight: 600;
}

.pageTitleSection .pageTitleInfo .breadcrumb {
  background-color: transparent;
}

.pageTitleSection .pageTitleInfo .breadcrumb li {
  color: #ffffff;
  font-size: 15px;
}

.pageTitleSection .pageTitleInfo .breadcrumb li a {
  color: #ffffff;
  font-size: 15px;
}

.pageTitleSection .pageTitleInfo .breadcrumb > li + li::before {
  content: " \276F\00a0 ";
}

/*=== 2.3 FORM ===*/
.homeContactContent form .btn {
  float: right;
  width: auto;
}

.homeContactContent form .formBtnArea .btn {
  float: none;
  width: auto;
}

.searchArea {
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-bottom: 70px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  height: 55px;
}

@media (min-width: 768px) {
  .searchArea {
    height: 70px;
  }
}

.searchArea > .input-group-addon {
  background-color: transparent;
  border: none;
  min-width: 60px;
  font-size: 18px;
  color: #666666;
}

.searchArea > .input-group-addon.button {
  height: 55px;
  border: none;
  line-height: 45px;
  background-color: #ea7066;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  display: inline-block;
  width: 25%;
  position: absolute;
  right: 0;
  z-index: 9;
}

@media (min-width: 768px) {
  .searchArea > .input-group-addon.button {
    height: 70px;
    font-size: 18px;
    line-height: 45px;
    width: 20%;
  }
}

.searchArea > .input-group-addon.button:hover {
  background-color: #ea7066;
}

.searchArea > .form-control {
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: 55px;
  font-size: 13px;
  color: #222222;
}

@media (min-width: 768px) {
  .searchArea > .form-control {
    height: 70px;
    font-size: 15px;
  }
}

.resultInfo h3 {
  font-family: 'Dosis', sans-serif;
  color: #666666;
  font-size: 28px;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.formPanel.panel {
  border: 0;
  margin-bottom: 30px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.formPanel.panel .panel-heading {
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}

.formPanel.panel .panel-heading .panel-title {
  font-size: 24px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  font-family: 'Dosis', sans-serif;
}

.formPanel.panel .panel-body {
  border: 1px solid #dedede;
  border-top: 0;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.formPanel.panel .panel-body .media-list li {
  padding: 10px 0;
  margin: 0;
}

.formPanel.panel .panel-body .media-list li .media-left.iconContent {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  float: left;
  padding: 0;
  margin-right: 20px;
  font-size: 16px;
}

.formPanel.panel .panel-body .media-list li .media-body.iconContent .media-heading {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.formPanel.panel .panel-body .media-list li .media-body.iconContent p {
  font-weight: 600;
  margin-bottom: 0;
}

.formPanel.panel .panel-body .media-list.blogListing li .media-heading a {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #666666;
}

.formPanel.panel .panel-body .media-list.blogListing li .media-heading a:hover {
  color: #a597e7 !important;
}

.formField .form-control {
  height: 50px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #222222;
  border-color: #e4e4e4;
  box-shadow: none;
  margin-bottom: 15px;
}

.formField .btn-primary {
  margin-bottom: 15px;
}

.formField .btn-primary:focus {
  outline: 0;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.formField .help-block {
  text-align: center;
  margin-bottom: 0;
}

.formField .help-block a:hover {
  color: #ea7066;
}

.errorSpace .form-control {
  border-color: rgba(102, 102, 102, 0.2);
  margin-bottom: 40px;
}

.not-brdr-radius {
  border-radius: 0 !important;
}

/*=== 2.4 PAGINATION ===*/
/*=== 2.5 ACCORDION ===*/
.accordionCommon .panel {
  margin-bottom: 30px;
  border: none;
}

.accordionCommon .panel-heading {
  display: block;
  padding: 15px 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
}

.accordionCommon .panel-heading span.iconBlock {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  color: #666666;
  display: inline-block;
  float: right;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.accordionCommon .panel-heading span.iconBlock.iconTransparent {
  background-color: transparent;
  box-shadow: none;
  color: #ffffff;
}

.accordionCommon span.separator {
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  position: absolute;
  top: 0;
  right: 60px;
}

.accordionCommon .panel-body {
  padding: 30px;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  border-top: none !important;
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
}

.accordionCommon .panel-body p {
  margin-bottom: 35px;
}

.accordionCommon .panel-body p:last-child {
  margin-bottom: 0;
}

/*=== 2.6 TABS ===*/
.tabCommon .nav {
  border-bottom: none;
  text-align: center;
}

.tabCommon .nav li {
  display: inline-block;
  float: none;
}

@media (min-width: 320px) {
  .tabCommon .nav li {
    padding: 6px 2px;
  }
}

@media (min-width: 768px) {
  .tabCommon .nav li {
    padding: 0 2px;
  }
}

.tabCommon .nav li a {
  padding: 0 25px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tabCommon .nav li.active a {
  background-color: #ea7066;
  color: #fffefe;
}

.tabCommon .tab-content {
  padding-top: 50px;
}

.tabCommon .tab-pane {
  border: 3px solid rgba(234, 112, 102, 0.3);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

@media (min-width: 320px) {
  .tabCommon .media-left {
    display: block;
    float: left;
    width: 100%;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .tabCommon .media-left {
    display: block;
    float: left;
    width: 100%;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .tabCommon .media-left {
    display: inline-block;
    float: left;
    width: auto;
    padding-right: 10px;
  }
}

.tabCommon .media-left img {
  width: 100%;
}

@media (min-width: 320px) {
  .tabCommon .media-body {
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .tabCommon .media-body {
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .tabCommon .media-body {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .tabCommon .media-body {
    padding: 50px 30px 0 0;
  }
}

.tabCommon .media-body .media-heading {
  font-family: 'Dosis', sans-serif;
  font-size: 28px;
  color: #ea7066;
  margin: 0 0 25px 0;
  text-transform: capitalize;
}

.tabCommon .media-body p {
  color: #222222;
  margin-bottom: 30px;
}

.tabCommon.tabLeft .nav {
  padding-right: 10px;
}

@media (min-width: 320px) {
  .tabCommon.tabLeft .nav {
    display: block;
  }
}

@media (min-width: 768px) {
  .tabCommon.tabLeft .nav {
    display: table-cell;
  }
}

.tabCommon.tabLeft .nav li {
  display: block;
  padding: 0 0 10px 0;
}

.tabCommon.tabLeft .nav li.active a {
  position: relative;
}

.tabCommon.tabLeft .nav li.active a:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ea7066;
  position: absolute;
  right: -9px;
  top: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabCommon.tabLeft .tab-content {
  display: table-cell;
  padding: 0;
}

/*=== 2.7 DATEPICKER ===*/
/*=== 2.8 THUMBNAIL ===*/
.thumbnail {
  padding: 0;
  border: none;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  position: relative;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.thumbnail > a img {
  border-radius: 9px 9px 0 0;
  width: 100%;
}

.thumbnail img {
  border-radius: 9px 9px 0 0;
  width: 100%;
}

/*=== 2.9 MODAL ===*/
.customModal .modal-content {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

@media (min-width: 320px) {
  .customModal .modal-dialog {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .customModal .modal-dialog {
    width: 350px;
  }
}

.customModal .formPanel.panel {
  margin-bottom: 0 !important;
}

/*=== 2.10 PORDUCT QUICK VIEW MODAL ===*/
.modal.quick-view.in .modal-dialog {
  -webkit-transform: translate(0, 25%);
  -ms-transform: translate(0, 25%);
  -o-transform: translate(0, 25%);
  transform: translate(0, 25%);
}

@media (max-width: 480px) {
  .quick-view .modal-dialog {
    width: 280px;
    margin: 0 auto;
  }
}

@media (min-width: 480px) {
  .quick-view .modal-dialog {
    width: 450px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .quick-view .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .quick-view .modal-dialog {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .quick-view .modal-dialog {
    width: 1030px;
  }
}

.quick-view .modal-content {
  padding: 50px 30px;
}

.quick-view .modal-content img {
  margin: auto;
}

@media (max-width: 480px) {
  .quick-view .modal-content .add-to-cart.btn-primary {
    margin-top: 15px;
  }
}

/*=== 2.10 PANEL ===*/
/*=== 2.11 PRICE RANGE SLIDER ===*/
/*=== 2.12 LIST ===*/
.topList li {
  color: rgba(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
  .topList li {
    margin-right: 5px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .topList li {
    margin-right: 10px;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .topList li {
    margin-right: 20px;
  }
}

.topList li i {
  width: 21px;
  height: 21px;
  display: inline-block;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 21px;
}

@media (min-width: 768px) {
  .topList li i {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .topList li i {
    margin-right: 5px;
  }
}

@media (min-width: 1200px) {
  .topList li i {
    margin-right: 10px;
  }
}

.topList li a {
  color: rgba(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
  .topList li a {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .topList li a {
    font-size: 14px;
  }
}

.topList li:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .functionList {
    width: 100%;
    float: left;
  }
}

@media (min-width: 992px) {
  .functionList {
    width: 100%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .functionList {
    width: 92%;
    float: right;
  }
}

.functionList > li {
  color: rgba(255, 255, 255, 0.8);
  float: left;
}

@media (min-width: 768px) {
  .functionList > li {
    margin-right: 5px;
    padding: 0;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .functionList > li {
    margin-right: 10px;
    padding: 0;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .functionList > li {
    margin-right: 15px;
    padding: 0 5px;
  }
}

.functionList > li span {
  margin: 0 5px;
}

.functionList > li i {
  width: 21px;
  height: 21px;
  display: inline-block;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 21px;
}

@media (min-width: 768px) {
  .functionList > li i {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .functionList > li i {
    margin-right: 10px;
  }
}

.functionList > li:last-child {
  margin-right: 0;
}

.functionList > li a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}

.functionList > li.LanguageList {
  width: 22%;
}

.functionList > li:first-child {
  margin-right: 0;
}

.functionList > li:first-child i {
  margin-right: 0;
}

/*=== 2.13 CART ===*/
.navbar-default .cart-dropdown {
  position: absolute;
  top: 26px;
  right: 90px;
}

@media (min-width: 768px) {
  .navbar-default .cart-dropdown {
    display: none;
  }
}

.cart-dropdown > a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}

.cart-dropdown > a i {
  width: 21px;
  height: 21px;
  display: inline-block;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 21px;
  margin-right: 0;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.shop-cart {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  margin-right: 0;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 5px;
  margin-top: 0;
  display: block;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shop-cart i {
  color: #ffffff;
  margin-right: 0;
}

.shop-cart .badge {
  position: absolute;
  top: -5px;
  right: -15px;
}

.cart-dropdown .dropdown-menu {
  width: 300px;
  border-top: 4px solid #ea7066;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  margin-top: 18px;
  padding: 20px 10px;
  height: 450px;
  right: -76px;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .cart-dropdown .dropdown-menu {
    width: 400px;
    padding: 25px;
    height: auto;
    overflow-y: inherit;
    margin-top: 11px;
    right: 21px;
  }
}

.cart-dropdown .dropdown-menu li:first-child {
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  color: #222222;
  text-transform: capitalize;
  padding-top: 0;
  padding-bottom: 10px;
  font-weight: 700;
}

.cart-dropdown .dropdown-menu li:first-child i {
  color: #222222;
  font-size: 20px;
  margin-right: 15px;
}

.cart-dropdown .dropdown-menu li {
  padding: 25px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}

.cart-dropdown .dropdown-menu li a {
  padding: 0;
  background-color: #ffffff;
  color: #666666;
}

.cart-dropdown .dropdown-menu li .cancel {
  margin: 0;
}

.cart-dropdown .dropdown-menu li .cancel i {
  position: absolute;
  top: 25px;
  right: 0;
  color: #666666;
  font-size: 18px;
  margin-right: 0;
}

.cart-dropdown .dropdown-menu li:last-child {
  border: none;
}

.cart-dropdown .dropdown-menu li:last-child span {
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  color: #222222;
  font-weight: 700;
  display: inline-block;
}

.cart-dropdown .dropdown-menu li:last-child .cart-total {
  float: left;
}

.cart-dropdown .dropdown-menu li:last-child .cart-price {
  float: right;
}

.cart-dropdown .dropdown-menu .cart-button {
  clear: both;
  margin-top: 25px;
  width: 100%;
  float: left;
}

.cart-dropdown .dropdown-menu .cart-button .btn-primary {
  width: 120px;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .cart-dropdown .dropdown-menu .cart-button .btn-primary {
    width: 162px;
    padding: 0 20px;
  }
}

.cart-dropdown .dropdown-menu .cart-button .btn-primary:first-child {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .cart-dropdown .dropdown-menu .cart-button .btn-primary:first-child {
    margin-right: 20px;
  }
}

.cart-dropdown .dropdown-menu .cart-button .btn-primary:last-child {
  background-color: #f8f8f8;
  color: #666666;
}

.cart-dropdown .dropdown-menu .media-left {
  width: 90px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background-color: #f3f3f3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0;
  float: left;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .cart-dropdown .dropdown-menu .media-left {
    margin-right: 20px;
  }
}

.cart-dropdown .dropdown-menu .media-body h4 {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  color: #222222;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 700;
}

.cart-dropdown .dropdown-menu .media-body .price span {
  margin-bottom: 7px;
}

.cart-dropdown .dropdown-menu span {
  margin-left: 0;
  margin-right: 0;
}

.cart-dropdown .dropdown-menu:before {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 7px solid #ea7066;
  margin-left: -10px;
  content: "";
  display: inline-block;
  right: 6px;
  position: absolute;
  top: -11px;
}

@media (min-width: 768px) {
  .LanguageList .sbSelector {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .LanguageList .sbSelector {
    font-size: 14px;
  }
}

/*=== 2.14 PAGER ===*/
.pagerArea > .pager {
  display: inline-block;
  margin: 50px auto 20px;
  text-align: left;
}

@media (min-width: 992px) {
  .pagerArea > .pager {
    text-align: center;
    margin: 80px auto 20px;
  }
}

.pagerArea > .pager li {
  padding: 0 8px;
}

@media (min-width: 992px) {
  .pagerArea > .pager li {
    padding: 0 15px;
  }
}

.pagerArea > .pager li a {
  height: 45px;
  border: none;
  line-height: 45px;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  text-transform: uppercase;
  color: #666666;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

@media (min-width: 992px) {
  .pagerArea > .pager li a {
    font-size: 16px;
  }
}

.pagerArea > .pager li a:hover {
  color: #ea7066;
}

.pagerArea > .pager li.active a {
  color: #ea7066;
}

.pagerArea > .pager li.prev a, .pagerArea > .pager li.next a {
  width: 80px;
  padding: 0 10px;
  background-color: #ea7066;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

@media (min-width: 992px) {
  .pagerArea > .pager li.prev a, .pagerArea > .pager li.next a {
    width: 114px;
    padding: 0 20px;
  }
}

.pagerArea > .pager li.prev a:hover, .pagerArea > .pager li.next a:hover {
  color: #ffffff;
}

.pagerArea > .pager li.prev {
  padding-left: 0;
}

.pagerArea > .pager li.prev a i {
  margin-right: 10px;
}

.pagerArea > .pager li.next {
  padding-right: 0;
}

.pagerArea > .pager li.next a i {
  margin-left: 10px;
}

/*=== 2.15 Extra ===*/
.errorMsg {
  text-align: center;
}

.errorInfo h3 {
  font-family: 'Dosis', sans-serif;
  font-size: 48px;
  color: #222222;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.errorInfo p {
  font-size: 28px;
  color: #202020;
  margin-bottom: 55px;
  line-height: 34px;
}

/*=== 2.16 Alert ===*/
.alerts {
  margin-bottom: 100px;
}

.alerts .tab-pane {
  border: none;
}

.alert-default {
  color: #ea7066;
  background-color: #fadbd9;
  border-color: #f5b8b3;
}

/*=== 2.17 Labels ===*/
.label {
  font-weight: 400;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 17px;
  margin-bottom: 15px;
  display: inline-block;
}

.label-default {
  background-color: #ea7066 !important;
}

.label-outline-default {
  color: #ea7066;
  background-color: transparent;
  border: 1px solid #ea7066;
}

.label-outline-primary {
  color: #337ab7;
  background-color: transparent;
  border: 1px solid #337ab7;
}

.label-outline-success {
  color: #5cb85c;
  background-color: transparent;
  border: 1px solid #5cb85c;
}

.label-outline-info {
  color: #5bc0de;
  background-color: transparent;
  border: 1px solid #5bc0de;
}

.label-outline-warning {
  color: #f0ad4e;
  background-color: transparent;
  border: 1px solid #f0ad4e;
}

.label-outline-danger {
  color: #d9534f;
  background-color: transparent;
  border: 1px solid #d9534f;
}

/*=== 2.18 Badges ===*/
.badge-link {
  color: #000;
  display: inline-block;
  padding-right: 15px;
}

.badge-link:hover, .badge-link:focus {
  color: #ea7066;
}

.badge {
  color: #ffffff;
  background-color: #ea7066;
  font-size: 10px;
  font-weight: 400;
  border-radius: 10px;
  padding: 3px 7px;
}

.active .badge {
  color: #ea7066 !important;
}

.nav-pills li {
  margin-right: 15px;
  display: inline-block;
}

.nav.nav-pills a {
  color: #000;
}

.nav.nav-pills a:hover,
.nav.nav-pills a:focus,
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #ffffff;
  background-color: #ea7066;
}

/*=== 2.19 Progress Bar ===*/
.progress-bar-default {
  background-color: #777777 !important;
}

/*=== 2.20 List Group ===*/
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  color: white;
  background-color: #ea7066;
  border-color: #ea7066;
}

.list-group-item.active .list-group-item-text {
  color: #ffffff;
}

/*=== 2.21 Panel ===*/
.panel-primary {
  border-color: #ea7066;
}

.panel-primary > .panel-heading {
  color: white;
  background-color: #ea7066;
  border-color: #ea7066;
}

/*=== 2.22 Jambotron ===*/
.jumbotron h1 {
  color: #ea7066;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 32px;
}

.jumbotron p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 20px;
}

/*=== 2.23 Tooltip ===*/
#tooltip .btn {
  margin: 0 auto 30px;
  display: block;
}

@media (min-width: 768px) {
  #tooltip .btn {
    display: inline-block;
    margin: 0 15px 30px;
  }
}

/*=== 3. HEADER ===*/
.navbar .container {
  position: relative;
}

.bb-fixed-header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}

/*=== 3.1 TOP COLOR BAR ===*/
.color-bar {
  width: 100%;
}

.color-bar .col-sm-1 {
  height: 3px;
  text-indent: -99999px;
}

.top-fixed {
  position: fixed;
  z-index: 2000;
}

/*=== 3.2 TOP INFO BAR ===*/
.top-info-bar {
  padding: 15px 0 12px 0;
  display: block;
  width: 100%;
  z-index: 1000;
  position: relative;
  top: 3px;
  -webkit-transition: height 0.15s ease;
  -moz-transition: height 0.15s ease;
  -ms-transition: height 0.15s ease;
  -o-transition: height 0.15s ease;
  transition: height 0.15s ease;
  height: 53px;
}

/*=== 3.3 HEADER TRANSPARENT ===*/
.navbar-default {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  z-index: 700;
}

.navbar-header .navbar-brand {
  float: left;
}

.navbar-header .navbar-brand > img {
  width: 100%;
}

@media (min-width: 320px) {
  .navbar-header .navbar-brand {
    height: auto;
    padding: 15px;
    width: 160px;
  }
}

@media (min-width: 768px) {
  .navbar-header .navbar-brand {
    height: 110px;
    padding: 30px 15px;
    width: 196px;
  }
}

.navbar-default .navbar-nav {
  background-color: #ffffff;
  margin-bottom: 0;
}

@media (min-width: 320px) {
  .navbar-default .navbar-nav {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav {
    height: auto;
    background-color: transparent;
  }
}

@media (min-width: 320px) {
  .navbar-default .navbar-nav.navbar-right {
    margin-right: -15px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav.navbar-right {
    margin-right: 0;
  }
}

.navbar-default .navbar-nav > li > a {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  background-color: transparent !important;
}

@media (min-width: 320px) {
  .navbar-default .navbar-nav > li > a {
    color: #000000;
    padding: 12px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li > a {
    margin: 0;
    padding: 23px 0;
  }
}

.navbar-default .navbar-nav > li > a > i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  display: block;
  color: #ffffff;
  font-size: 16px;
}

@media (min-width: 320px) {
  .navbar-default .navbar-nav > li > a > i {
    display: inline-block;
    margin: 0 10px 0 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li > a > i {
    display: block;
    margin: 0 auto 12px auto;
  }
}

.navbar-default .navbar-nav > li > a > span {
  padding: 0;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
}

@media (min-width: 320px) {
  .navbar-default .navbar-nav > li > a > span {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li > a > span {
    width: auto;
    padding: 0 15px;
    display: block;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li > a > span {
    width: 110px;
    padding: 0;
  }
}

.navbar-default .navbar-nav > li:last-child > a > span {
  width: auto;
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li:last-child > a > span {
    padding-left: 15px;
  }
}

.navbar-default .navbar-nav > li.active > a, .navbar-default .navbar-nav > li.active > a:hover, .navbar-default .navbar-nav > li.active > a:focus {
  background-color: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.navbar-default .navbar-nav > li.open > a, .navbar-default .navbar-nav > li.open > a:hover > a:focus {
  background-color: transparent;
}

.navbar-default .navbar-nav > li.dropdown .dropdown-menu a {
  padding: 3px 20px;
  margin-bottom: 0;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.open > a:after {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.open > a:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    margin-left: -10px;
    content: "";
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 102px;
  }
}

.navbar-nav > li.color-1.dropdown a:after {
  border-bottom: 7px solid #f0c24b;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu > a:after {
  border-bottom: 7px solid #b5d56a;
}

.navbar-nav > li.color-3.dropdown a:after {
  border-bottom: 7px solid #ea7066;
}

.navbar-nav > li.color-4.dropdown a:after {
  border-bottom: 7px solid #84bed6;
}

.navbar-nav > li.color-5.dropdown a:after {
  border-bottom: 7px solid #a597e7;
}

.navbar-nav > li.color-6.dropdown a:after {
  border-bottom: 7px solid #ea77ad;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 20%;
    margin-top: -10px;
    content: "";
    display: inline-block;
    left: -11px;
    position: absolute;
  }
}

.navbar-nav > li.color-3.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
  border-right: 7px solid #ea7066;
}

.navbar-nav > li.color-4.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
  border-right: 7px solid #84bed6;
}

.navbar-nav > li.color-5.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
  border-right: 7px solid #a597e7;
}

.navbar-nav > li.color-6.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
  border-right: 7px solid #ea77ad;
}

.navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active a {
  background-color: transparent;
}

.navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active a:hover {
  background-color: #f0c24b;
}

.navbar-nav > li.dropdown.megaDropMenu {
  position: static;
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
  margin: -2px auto 0 auto;
  right: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    padding: 0;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 15px;
    padding: 10px 5px 0;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 1140px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
  width: 100%;
  float: left;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 15px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  float: left;
  font-weight: 700;
  font-family: 'Dosis', sans-serif;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 0;
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px;
    font-size: 24px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
  color: #666666;
  font-size: 14px;
  float: left;
  width: 100%;
  background-color: transparent;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 6px 12px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover {
  padding-left: 22px;
}

.navbar-nav > li.dropdown.megaDropMenu.open > a > span {
  color: #b5d56a;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: 0;
  padding: 5px;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu {
    min-width: 230px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu {
    min-width: 210px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu {
    min-width: 230px;
  }
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
  color: #666666;
  font-size: 14px;
  float: none;
  width: 100%;
  background-color: transparent;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 6px 12px 6px 24px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover {
  padding-left: 22px;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu {
  margin-left: 4px;
}

@media (min-width: 320px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 6px 12px 6px 36px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a:hover {
  padding-left: 22px;
}

.navbar-nav > li.dropdown.singleDrop > a {
  display: block;
  position: relative;
}

.navbar-nav > li.dropdown.singleDrop > a:after {
  display: inline-block;
  width: auto;
  height: auto;
  font-family: "FontAwesome";
  margin-left: 5px;
  vertical-align: middle;
  content: "\f107";
  border: 0px;
  color: inherit;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 15px;
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop > a:after {
    content: '';
  }
}

.navbar-nav > li.dropdown.singleDrop.open > a:after {
  content: "\f106";
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop.open > a:after {
    content: '';
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a {
  position: relative;
}

.dropdown-submenu a i {
  position: absolute;
  right: 10px;
  top: 13px;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: 0 !important;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

@media (min-width: 480px) {
  .dropdown-submenu > .dropdown-menu {
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-submenu > .dropdown-menu {
    left: 100%;
  }
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.navbar-toggle {
  margin-top: 20px;
}

.navbar-toggle {
  background-color: #ffffff;
}

.navbar-default .navbar-nav {
  height: 340px;
  overflow-x: hidden;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav {
    height: auto;
    overflow-x: visible;
    overflow-y: visible;
  }
}

.navbar-collapse.in {
  overflow-y: visible;
}

@media (min-width: 320px) {
  .navbar-collapse {
    -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 768px) {
  .navbar-collapse {
    border-color: #ffffff;
    box-shadow: none;
  }
}

.navbar-collapse li.dropdown .dropdown-menu {
  box-shadow: none;
}

@media (min-width: 320px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    border: 0;
  }
}

@media (min-width: 320px) and (min-width: 768px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    float: left;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}

.navbar-collapse li.dropdown.open .dropdown-menu {
  display: block;
}

@media (min-width: 320px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

.navbar-collapse li.dropdown.dropdown-submenu.open .dropdown-menu {
  display: block;
}

/*=== 3.4 LIGHT HEADER ===*/
.navbar-default.lightHeader {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

@media (min-width: 320px) {
  .navbar-default.lightHeader {
    height: 70px;
    top: 3px;
  }
}

@media (min-width: 768px) {
  .navbar-default.lightHeader {
    height: 110px;
  }
}

.navbar-default.lightHeader .navbar-nav > li > a {
  color: #000000;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-1 > a {
  color: #f0c24b;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-2 > a {
  color: #b5d56a;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-3 > a {
  color: #ea7066;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-4 > a {
  color: #84bed6;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-5 > a {
  color: #a597e7;
}

.navbar-default.lightHeader .navbar-nav > li.active.color-6 > a {
  color: #ea77ad;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-1 > a > span {
    border-right: 2px solid #f0c24b;
  }
}

.navbar-nav > li.color-1 > a:hover span {
  color: #f0c24b;
}

.navbar-nav > li.color-1 > a:active span {
  color: #f0c24b !important;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-1.singleDrop > .dropdown-menu {
    border-top: 4px solid #f0c24b;
  }
}

.navbar-nav > li.color-1.singleDrop > .dropdown-menu li a:hover {
  background-color: #f0c24b;
  color: #ffffff;
}

.navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active > a, .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active li.active a {
  color: #f0c24b;
}

.navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active a:hover, .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-1.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #f0c24b;
  }
}

.navbar-nav > li.color-1.singleDrop.open a > span {
  color: #f0c24b;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-2 > a > span {
    border-right: 2px solid #b5d56a;
  }
}

.navbar-nav > li.color-2 > a:hover span {
  color: #b5d56a;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu {
    border-top: 4px solid #b5d56a;
  }
}

.navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  color: #b5d56a;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover {
  background-color: #b5d56a;
  color: #ffffff;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active > a, .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active li.active a {
  color: #b5d56a;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active a:hover, .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active li.active a:hover {
  color: #ffffff;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu .open a > span {
  color: #b5d56a;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu > a {
  display: block;
  position: relative;
}

.navbar-nav > li.color-2.dropdown.megaDropMenu > a:after {
  display: inline-block;
  width: auto;
  height: auto;
  font-family: "FontAwesome";
  margin-left: 5px;
  vertical-align: middle;
  content: "\f107";
  border: 0px;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 15px;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-2.dropdown.megaDropMenu > a:after {
    content: '';
  }
}

.navbar-nav > li.color-2.dropdown.megaDropMenu.open > a:after {
  content: "\f106";
}

@media (min-width: 768px) {
  .navbar-nav > li.color-2.dropdown.megaDropMenu.open > a:after {
    content: '';
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.color-3 > a > span {
    border-right: 2px solid #ea7066;
  }
}

.navbar-nav > li.color-3 > a:hover span {
  color: #ea7066;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-3.singleDrop > .dropdown-menu {
    border-top: 4px solid #ea7066;
  }
}

.navbar-nav > li.color-3.singleDrop > .dropdown-menu li a:hover {
  background-color: #ea7066;
  color: #ffffff;
}

.navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active > a, .navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active li.active a {
  color: #ea7066;
}

.navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active a:hover, .navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-3.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #ea7066;
  }
}

.navbar-nav > li.color-3.singleDrop.open a > span {
  color: #ea7066;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-4 > a > span {
    border-right: 2px solid #84bed6;
  }
}

.navbar-nav > li.color-4 > a:hover span {
  color: #ea7066;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-4.singleDrop > .dropdown-menu {
    border-top: 4px solid #84bed6;
  }
}

.navbar-nav > li.color-4.singleDrop > .dropdown-menu li a:hover {
  background-color: #84bed6;
  color: #ffffff;
}

.navbar-nav > li.color-4.singleDrop > .dropdown-menu li.active > a, .navbar-nav > li.color-4.singleDrop > .dropdown-menu li.active li.active a {
  color: #84bed6;
}

.navbar-nav > li.color-4.singleDrop > .dropdown-menu li.active a:hover, .navbar-nav > li.color-4.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-4.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #84bed6;
  }
}

.navbar-nav > li.color-4.singleDrop.open a > span {
  color: #84bed6;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-5 > a > span {
    border-right: 2px solid #a597e7;
  }
}

.navbar-nav > li.color-5 > a:hover span {
  color: #a597e7;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-5.singleDrop > .dropdown-menu {
    border-top: 4px solid #a597e7;
  }
}

.navbar-nav > li.color-5.singleDrop > .dropdown-menu li a:hover {
  background-color: #a597e7;
  color: #ffffff;
}

.navbar-nav > li.color-5.singleDrop > .dropdown-menu li.active > a, .navbar-nav > li.color-5.singleDrop > .dropdown-menu li.active li.active a {
  color: #a597e7;
}

.navbar-nav > li.color-5.singleDrop > .dropdown-menu li.active a:hover, .navbar-nav > li.color-5.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-5.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #a597e7;
  }
}

.navbar-nav > li.color-5.singleDrop.open a > span {
  color: #a597e7;
}

.navbar-nav > li.color-6 > a:hover span {
  color: #ea77ad;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-6.singleDrop > .dropdown-menu {
    border-top: 4px solid #ea77ad;
  }
}

.navbar-nav > li.color-6.singleDrop > .dropdown-menu li a:hover {
  background-color: #ea77ad;
  color: #ffffff;
}

.navbar-nav > li.color-6.singleDrop > .dropdown-menu li.active > a, .navbar-nav > li.color-6.singleDrop > .dropdown-menu li.active li.active a {
  color: #ea77ad;
}

.navbar-nav > li.color-6.singleDrop > .dropdown-menu li.active a:hover, .navbar-nav > li.color-6.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-nav > li.color-6.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #ea77ad;
  }
}

.navbar-nav > li.color-6.singleDrop.open a > span {
  color: #ea77ad;
}

.navbar-nav > li:last-child > a > span {
  border-right: none;
}

.navbar-right {
  margin-right: 0;
}

.navbar-default .navbar-toggle, .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: #ea7066;
  border-color: #ea7066;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-toggle .icon-bar, .navbar-default .navbar-toggle:focus .icon-bar, .navbar-default .navbar-toggle:hover .icon-bar {
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .bb-fixed-header .navbar-nav > li.dropdown.open > a:after {
    top: 83px;
  }
}

#menuBar.bb-fixed-header {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1030 !important;
  top: 3px;
}

@media (min-width: 480px) {
  #menuBar.bb-fixed-header {
    height: 70px;
  }
}

@media (min-width: 768px) {
  #menuBar.bb-fixed-header {
    height: 90px;
  }
}

@media (min-width: 480px) {
  #menuBar.bb-fixed-header .navbar-brand {
    height: auto;
    padding: 15px;
    width: 160px;
  }
}

@media (min-width: 768px) {
  #menuBar.bb-fixed-header .navbar-brand {
    height: auto;
    padding: 20px 15px;
    width: 190px;
  }
}

@media (min-width: 320px) {
  #menuBar.bb-fixed-header .navbar-nav > li > a {
    padding: 12px;
  }
}

@media (min-width: 768px) {
  #menuBar.bb-fixed-header .navbar-nav > li > a {
    padding: 13px 0;
  }
}

/*=== 4. FOOTER ===*/
footer {
  position: relative;
}

footer .color-bar {
  position: absolute;
  top: 0;
}

/*=== 4.1 FOOTER INFO ===*/
.footerInfoArea {
  background-attachment: fixed;
  width: 100%;
  background-repeat: repeat;
  padding: 60px 0;
}

.footerTitle {
  display: block;
  margin-bottom: 30px;
}

.footerTitle a {
  width: 150px;
  height: 46px;
  display: block;
}

.footerTitle a img {
  width: 100%;
}

.footerTitle h4 {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.footerInfo p {
  margin-bottom: 30px;
  color: #d8e3f0;
}

.footerInfo .input-group {
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
}

.footerInfo .input-group .form-control {
  background-color: #f5f5f5;
  border: 0;
  color: #666666;
  height: 40px;
  width: 80%;
}

.footerInfo .input-group .input-group-addon {
  border-radius: 0 4px 4px 0;
  background-color: #ea7066;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  float: left;
  width: 20%;
  height: 40px;
}

.footerInfo .input-group .input-group-addon i {
  color: #ffffff;
}

.footerInfo .input-group .input-group-addon:hover {
  background-color: #ea7066;
  border-color: #ea7066;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.footerList > li > a {
  color: #d8e3f0;
}

.footerList > li > a i {
  margin: 0 10px 25px 0;
}

.postLink li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.postLink li:last-child {
  border-bottom: none;
}

.postLink li .media-body .media-heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  text-overflow: none;
  white-space: normal;
  overflow: hidden;
}

.postLink li .media-body .media-heading a {
  color: #d8e3f0;
}

.postLink li .media-body p {
  margin-bottom: 0;
}

.postLink li .media-left .img-rounded {
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  width: 60px;
  height: 60px;
}

/*=== 4.2 COPY RIGHT ===*/
.copyRight {
  display: block;
  background-color: #041319;
  padding: 20px 0;
}

@media (min-width: 320px) {
  .copyRight .list-inline {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline {
    text-align: right;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .copyRight .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .copyRight .list-inline li {
    padding: 0 5px;
  }
}

.copyRight .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
}

.copyRight .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .copyRight .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .copyRight .list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.copyRightText p {
  margin-bottom: 0;
  color: #d8e3f0;
}

.copyRightText p a {
  color: #d8e3f0;
}

.copyRightText p a:hover {
  color: #ea7066;
}

@media (min-width: 320px) {
  .copyRightText p {
    font-size: 11px;
    text-align: center;
    line-height: 21px;
  }
}

@media (min-width: 768px) {
  .copyRightText p {
    font-size: 13px;
    text-align: left;
    line-height: 28px;
  }
}

/*================= 5. HOME ==================*/
/*=== 4. FOOTER ===*/
footer {
  position: relative;
}

footer .color-bar {
  position: absolute;
  top: 0;
}

/*=== 4.1 FOOTER INFO ===*/
.footerInfoArea {
  background-attachment: fixed;
  width: 100%;
  background-repeat: repeat;
  padding: 60px 0;
}

.footerTitle {
  display: block;
  margin-bottom: 30px;
}

.footerTitle a {
  width: 150px;
  height: 46px;
  display: block;
}

.footerTitle a img {
  width: 100%;
}

.footerTitle h4 {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.footerInfo p {
  margin-bottom: 30px;
  color: #d8e3f0;
}

.footerInfo .input-group {
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 0 rgba(255, 255, 255, 0.1);
}

.footerInfo .input-group .form-control {
  background-color: #f5f5f5;
  border: 0;
  color: #666666;
  height: 40px;
  width: 80%;
}

.footerInfo .input-group .input-group-addon {
  border-radius: 0 4px 4px 0;
  background-color: #ea7066;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  float: left;
  width: 20%;
  height: 40px;
}

.footerInfo .input-group .input-group-addon i {
  color: #ffffff;
}

.footerInfo .input-group .input-group-addon:hover {
  background-color: #ea7066;
  border-color: #ea7066;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.footerList > li > a {
  color: #d8e3f0;
}

.footerList > li > a i {
  margin: 0 10px 25px 0;
}

.postLink li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.postLink li:last-child {
  border-bottom: none;
}

.postLink li .media-body .media-heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  text-overflow: none;
  white-space: normal;
  overflow: hidden;
}

.postLink li .media-body .media-heading a {
  color: #d8e3f0;
}

.postLink li .media-body p {
  margin-bottom: 0;
}

.postLink li .media-left .img-rounded {
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  width: 60px;
  height: 60px;
}

/*=== 4.2 COPY RIGHT ===*/
.copyRight {
  display: block;
  background-color: #041319;
  padding: 20px 0;
}

@media (min-width: 320px) {
  .copyRight .list-inline {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline {
    text-align: right;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .copyRight .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .copyRight .list-inline li {
    padding: 0 5px;
  }
}

.copyRight .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
}

.copyRight .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .copyRight .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .copyRight .list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.copyRightText p {
  margin-bottom: 0;
  color: #d8e3f0;
}

.copyRightText p a {
  color: #d8e3f0;
}

.copyRightText p a:hover {
  color: #ea7066;
}

@media (min-width: 320px) {
  .copyRightText p {
    font-size: 11px;
    text-align: center;
    line-height: 21px;
  }
}

@media (min-width: 768px) {
  .copyRightText p {
    font-size: 13px;
    text-align: left;
    line-height: 28px;
  }
}

/*=== 5.1 BANNER ===*/
.bannercontainer .tp-rightarrow.default {
  z-index: 98;
}

.bannercontainer .fullscreenbanner-container {
  width: 100%;
  position: relative;
  padding: 0;
}

.bannercontainer .fullscreenbanner-container .tp-bullets.preview4 {
  opacity: 1;
  height: auto;
}

@media (min-width: 992px) {
  .bannercontainer .fullscreenbanner-container .tp-bullets.preview4 {
    bottom: 30px !important;
  }
}

.bannercontainer .fullscreenbanner-container .tp-bullets.preview4 .bullet {
  display: none !important;
}

.bannercontainer .fullscreenbanner-container .tparrows.preview4 .tp-arr-iwrapper {
  display: none !important;
}

.tp-bullets {
  z-index: 99;
}

@media (min-width: 768px) {
  .tparrows.preview4:hover:after {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  .tp-leftarrow.preview4:hover:after {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

.fullscreenbanner {
  color: #ffffff;
  width: 100%;
  position: relative;
}

.tp-caption.rs-caption-1,
.tp-caption.rs-caption-2,
.tp-caption.rs-caption-3 {
  white-space: normal !important;
  width: inherit;
  font-family: 'Dosis', sans-serif;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 320px) {
  .tp-caption.rs-caption-1,
  .tp-caption.rs-caption-2,
  .tp-caption.rs-caption-3 {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .tp-caption.rs-caption-1,
  .tp-caption.rs-caption-2,
  .tp-caption.rs-caption-3 {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .tp-caption.rs-caption-1,
  .tp-caption.rs-caption-2,
  .tp-caption.rs-caption-3 {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .tp-caption.rs-caption-1,
  .tp-caption.rs-caption-2,
  .tp-caption.rs-caption-3 {
    width: 55%;
  }
}

.tp-caption.rs-caption-1 a.btn-primary, .tp-caption.rs-caption-1 a.btn-primary:hover, .tp-caption.rs-caption-1 a.btn-primary:focus,
.tp-caption.rs-caption-2 a.btn-primary,
.tp-caption.rs-caption-2 a.btn-primary:hover,
.tp-caption.rs-caption-2 a.btn-primary:focus,
.tp-caption.rs-caption-3 a.btn-primary,
.tp-caption.rs-caption-3 a.btn-primary:hover,
.tp-caption.rs-caption-3 a.btn-primary:focus {
  color: #ffffff;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .tp-caption.rs-caption-1 a.btn-primary, .tp-caption.rs-caption-1 a.btn-primary:hover, .tp-caption.rs-caption-1 a.btn-primary:focus,
  .tp-caption.rs-caption-2 a.btn-primary,
  .tp-caption.rs-caption-2 a.btn-primary:hover,
  .tp-caption.rs-caption-2 a.btn-primary:focus,
  .tp-caption.rs-caption-3 a.btn-primary,
  .tp-caption.rs-caption-3 a.btn-primary:hover,
  .tp-caption.rs-caption-3 a.btn-primary:focus {
    padding: 0 10px;
    margin-top: -20px;
  }
}

@media (min-width: 992px) {
  .tp-caption.rs-caption-1 a.btn-primary, .tp-caption.rs-caption-1 a.btn-primary:hover, .tp-caption.rs-caption-1 a.btn-primary:focus,
  .tp-caption.rs-caption-2 a.btn-primary,
  .tp-caption.rs-caption-2 a.btn-primary:hover,
  .tp-caption.rs-caption-2 a.btn-primary:focus,
  .tp-caption.rs-caption-3 a.btn-primary,
  .tp-caption.rs-caption-3 a.btn-primary:hover,
  .tp-caption.rs-caption-3 a.btn-primary:focus {
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
  }
}

.tp-caption.rs-caption-1 {
  text-transform: capitalize;
  font-weight: 700;
}

@media (min-width: 320px) {
  .tp-caption.rs-caption-1 {
    font-size: 30px !important;
    line-height: 2px !important;
    padding: 0 10px !important;
  }
}

@media (min-width: 768px) {
  .tp-caption.rs-caption-1 {
    font-size: 45px !important;
    line-height: 40px !important;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .tp-caption.rs-caption-1 {
    font-size: 50px !important;
    line-height: 40px !important;
  }
}

.tp-caption.rs-caption-1 span {
  color: #ea7066;
}

.tp-caption.rs-caption-2 {
  font-weight: 400;
}

@media (min-width: 320px) {
  .tp-caption.rs-caption-2 {
    font-size: 15px !important;
    line-height: 17px !important;
    padding: 0 10px !important;
  }
}

@media (min-width: 768px) {
  .tp-caption.rs-caption-2 {
    display: block;
    font-size: 14px !important;
    line-height: 17px !important;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .tp-caption.rs-caption-2 {
    font-size: 25px !important;
    line-height: 31px !important;
  }
}

.tp-caption.rs-caption-2,
.tp-caption.rs-caption-3 {
  padding: 0 10px !important;
}

.fullscreenbanner .tp-dottedoverlay.twoxtwo {
  background: transparent;
  background-image: none !important;
  z-index: 1;
}

.tparrows.preview4 {
  width: 47px;
  height: 47px;
}

.tparrows.preview4:after {
  line-height: 47px;
  width: 47px;
  height: 47px;
  background-color: #ffffff;
  color: #666666;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
}

.tp-rightarrow.preview4 {
  right: 3% !important;
}

.tp-rightarrow.preview4:after {
  content: '\e81e';
}

.tp-leftarrow.preview4 {
  left: 3% !important;
}

.tp-leftarrow.preview4:after {
  content: '\e81f';
}

/*=== 5.2 FEATURES SECTION ===*/
.featureSection {
  position: relative;
}

@media (min-width: 320px) {
  .featureSection {
    padding: 50px 0;
  }
}

@media (min-width: 768px) {
  .featureSection {
    padding: 60px 0;
  }
}

.linkIcon, .locateArrow {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.linkSection {
  width: 100%;
  z-index: 500;
}

@media (min-width: 320px) {
  .linkSection {
    position: relative;
  }
}

@media (min-width: 768px) {
  .linkSection {
    margin-top: -90px;
  }
}

.sectionLink {
  width: 100%;
  height: 190px;
  display: block;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.sectionLink .linkIcon {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #ffffff;
  border-width: 6px;
  border-style: solid;
  top: -50px;
  font-size: 30px;
  line-height: 90px;
  color: #666666;
}

.sectionLink .linkText {
  line-height: 190px;
  display: block;
  font-family: 'Dosis', sans-serif;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 1px;
}

.sectionLink .locateArrow {
  font-size: 18px;
  color: #ffffff;
  bottom: 27px;
  margin-left: -10px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

@media (min-width: 320px) {
  .sectionLink {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) {
  .sectionLink {
    margin-bottom: 0;
  }
}

.sectionLink:hover .border-color-1 {
  color: #f0c24b;
}

.sectionLink:hover .border-color-2 {
  color: #b5d56a;
}

.sectionLink:hover .border-color-3 {
  color: #ea7066;
}

.sectionLink:hover .border-color-4 {
  color: #84bed6;
}

.sectionLink:hover .locateArrow {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.media-body h3.media-heading {
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.featuresContent {
  margin-bottom: 40px;
}

.featuresContent .media-left {
  width: 76px;
  height: 76px;
  padding: 6px;
  margin-right: 20px;
  display: inline-block;
  float: left;
  -webkit-box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.featuresContent .media-left i {
  width: 62px;
  height: 62px;
  line-height: 62px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #ffffff;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.featuresContent .media-left:hover i {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

/*=== 5.3 PROMOTION ===*/
.promotionWrapper {
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #ffffff;
  padding: 75px 0;
}

.promotionWrapper .promotionInfo {
  text-align: center;
}

.promotionWrapper .promotionInfo h2 {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 40px;
  margin: 0 0 23px 0;
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .promotionWrapper .promotionInfo h2 {
    line-height: 36px;
  }
}

@media (min-width: 768px) {
  .promotionWrapper .promotionInfo h2 {
    line-height: 33px;
  }
}

.promotionWrapper .promotionInfo p {
  margin: 0 0 30px 0;
  color: #ffffff;
}

@media (min-width: 768px) {
  .promotionWrapper .promotionInfo p {
    line-height: 22px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .promotionWrapper .promotionInfo p {
    line-height: 15px;
    font-size: 18px;
  }
}

/*=== 5.4 ABOUT SCHOOL ===*/
.schoolInfo h2 {
  font-family: 'Dosis', sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-transform: capitalize;
  color: #ea7066;
  font-weight: 700;
}

@media (min-width: 320px) {
  .schoolInfo h2 {
    margin: 25px 0;
  }
}

@media (min-width: 768px) {
  .schoolInfo h2 {
    margin: 0 0 25px 0;
  }
}

.schoolInfo p {
  font-size: 15px;
  color: #222222;
  margin-bottom: 25px;
}

.schoolInfo .btn-primary {
  margin-top: 20px;
}

.para-list.list-unstyled {
  margin-bottom: 35px;
}

.para-list.list-unstyled li {
  color: #666666;
  font-size: 15px;
  margin-bottom: 10px;
}

.para-list.list-unstyled li i {
  margin-right: 10px;
}

/*=== 5.5 SERVICES SECTION ===*/
.servicesSection {
  background-image: url("../img/home/pattern-light.png");
  width: 100%;
  background-repeat: repeat;
  height: auto;
  padding-bottom: 60px;
}

.servicesContent {
  margin-bottom: 40px;
}

.servicesContent .media-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #ffffff;
  text-align: center;
  display: inline-block;
  float: left;
}

.servicesContent .media-left i {
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 70px;
  height: 70px;
  line-height: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.servicesContent .media-left:hover i {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.servicesContent .media-body p {
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 320px) {
  .servicesContent.rightAlign .media-left {
    float: left;
    margin: 0 20px 0 0;
  }
}

@media (min-width: 768px) {
  .servicesContent.rightAlign .media-left {
    float: right;
    margin: 0 0 0 20px;
  }
}

@media (min-width: 320px) {
  .servicesContent.rightAlign .media-body .media-heading, .servicesContent.rightAlign .media-body > p {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .servicesContent.rightAlign .media-body .media-heading, .servicesContent.rightAlign .media-body > p {
    text-align: right;
  }
}

/*=== 5.6 COURSES SECTION ===*/
.block h3 {
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 0 20px 0;
}

.block h3 a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

@media (min-width: 320px) {
  .block h3 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .block h3 {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .block h3 {
    font-size: 24px;
  }
}

.block .list-unstyled, .block .list-inline {
  margin-bottom: 0;
}

.block .list-unstyled li, .block .list-inline li {
  line-height: 21px;
  color: #666666;
  margin-bottom: 5px;
}

.block .list-unstyled li i, .block .list-inline li i {
  margin-right: 6px;
}

.block .list-unstyled li a, .block .list-inline li a {
  color: #666666;
}

.block .list-inline .btn-primary {
  background-color: #ffffff !important;
  color: #666666;
  border: 2px solid transparent !important;
}

.block .list-inline .btn-primary:hover {
  color: #ffffff;
  background-color: #f0c24b !important;
}

.block .list-inline .btn-link {
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  box-shadow: none;
}

.block .list-inline .btn-link:hover {
  box-shadow: none !important;
}

.block .list-inline.btn-yellow .btn-primary:hover {
  background-color: #f0c24b;
}

.block .list-inline.btn-yellow .btn-link:hover {
  color: #f0c24b;
}

.block .list-inline.btn-green .btn-primary:hover {
  background-color: #b5d56a !important;
}

.block .list-inline.btn-green .btn-link:hover {
  color: #b5d56a;
}

.block .list-inline.btn-red .btn-primary:hover {
  background-color: #ea7066 !important;
}

.block .list-inline.btn-red .btn-link:hover {
  color: #ea7066 !important;
}

.block .list-inline.btn-sky .btn-primary:hover {
  background-color: #84bed6 !important;
}

.block .list-inline.btn-sky .btn-link:hover {
  color: #84bed6;
}

.block .list-inline.btn-purple .btn-primary:hover {
  background-color: #a597e7 !important;
}

.block .list-inline.btn-purple .btn-link:hover {
  color: #a597e7;
}

.block .list-inline.btn-pink .btn-primary:hover {
  background-color: #ea77ad !important;
}

.block .list-inline.btn-pink .btn-link:hover {
  color: #ea77ad;
}

.coursesSection {
  padding-bottom: 80px;
}

.sticker {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 65px;
  height: 65px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 65px;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sticker:hover {
  -webkit-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

.thumbnailContent.alt {
  box-shadow: none;
}

.thumbnailContent.alt > .caption {
  background-color: transparent;
}

.thumbnailContent.alt > .caption .table {
  border-color: #dedede !important;
}

.thumbnailContent.alt > .caption .table.table-curved thead tr th {
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  height: 44px;
  line-height: 44px;
}

.thumbnailContent.alt > .caption .table.table-curved thead tr th:first-child {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
}

.thumbnailContent.alt > .caption .table.table-curved thead tr th:first-child i {
  color: #c09933;
}

.thumbnailContent.alt > .caption .table.table-curved tbody tr td {
  padding: 0;
  text-align: center;
  height: 109px;
  vertical-align: middle;
  font-size: 14px;
  color: #666666;
  border-color: #dedede !important;
}

.thumbnailContent.alt > .caption .table.table-curved tbody tr td:first-child {
  font-weight: 600;
}

.thumbnailContent.alt > .caption .table.table-curved tbody tr td span {
  display: block;
  font-family: 'Dosis', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.thumbnailContent.alt > .caption .imagesPart {
  display: block;
  width: 100%;
}

.thumbnailContent.alt > .caption .imagesPart img {
  display: inline-block;
  float: left;
  width: 49%;
  margin: 15px 2% 25px 0;
}

.thumbnailContent.alt > .caption .imagesPart img:last-child {
  margin-right: 0;
}

.thumbnailContent .caption {
  border-top: 5px solid #ffffff;
  background-color: #f8f8f8;
  padding: 15px;
}

/*=== 5.7 TEAM SECTION ===*/
.owl-carousel.teamSlider .owl-nav {
  height: 0px;
}

@media (min-width: 320px) {
  .owl-carousel.teamSlider .owl-nav {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter: alpha(opacity=1);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .owl-carousel.teamSlider .owl-nav {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

.owl-carousel.teamSlider:hover .owl-nav {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .teamSlider.owl-carousel .owl-prev {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .teamSlider.owl-carousel .owl-prev {
    margin-left: -50px !important;
  }
}

@media (min-width: 320px) {
  .teamSlider.owl-carousel .owl-next {
    margin-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .teamSlider.owl-carousel .owl-next {
    margin-right: -50px !important;
  }
}

.teamSection {
  background-image: url("../img/home/pattern-light.png");
  width: 100%;
  background-repeat: repeat;
  height: auto;
}

.teamImage {
  width: 150px;
  height: 150px;
  display: block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: relative;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.teamImage .maskingContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.teamImage .maskingContent .list-inline {
  text-align: center;
}

.teamImage .maskingContent .list-inline li a {
  line-height: 150px;
  color: #ffffff;
}

.teamInfo h3 {
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 15px 0 10px 0;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.teamInfo h3 a {
  color: #222222;
}

.teamInfo h3 a:hover {
  color: #ea7066;
}

.teamInfo p {
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.teamInfo.teamTeacher {
  margin-bottom: 50px;
}

.teamInfo.teamTeacher p {
  color: rgba(34, 34, 34, 0.7);
}

.teamContent {
  width: 150px;
  margin: 0 auto;
}

.teamContent .teamInfo h3 a {
  color: #ffffff;
}

.teamContent:hover .teamImage .maskingContent {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.teamContent:hover .teamInfo h3 a {
  color: #f0c24b;
}

.teamContent.teamAdjust {
  margin: 0 auto;
}

.teamContent.teamAdjust .teamInfo h3 a {
  color: #ea7066;
}

.teamContent.teamAdjust .teamInfo h3 a:hover {
  color: #ea7066;
}

@media (min-width: 320px) {
  .teamStyle {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .teamStyle {
    margin-bottom: 80px;
  }
}

@media (min-width: 320px) {
  .teamStyle .media-left {
    width: 100%;
    display: block;
    float: left;
    padding: 0 0 30px 0;
  }
}

@media (min-width: 768px) {
  .teamStyle .media-left {
    width: 150px;
    display: inline-block;
    float: left;
    padding-right: 30px;
  }
}

.teamStyle .media-left img {
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.teamStyle .media-body h4 {
  font-size: 20px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 12px;
}

.teamStyle .media-body h4 a {
  color: #222222;
}

.teamStyle .media-body h4 a:hover {
  color: #ea7066;
}

.teamStyle .media-body h5 {
  color: rgba(34, 34, 34, 0.7);
  font-size: 15px;
}

.teamStyle .media-body .list-inline {
  text-align: left;
}

@media (min-width: 320px) {
  .teamStyle .media-body .list-inline {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .teamStyle .media-body .list-inline {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .teamStyle .media-body .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .teamStyle .media-body .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .teamStyle .media-body .list-inline li {
    padding: 0 5px;
  }
}

.teamStyle .media-body .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
}

.teamStyle .media-body .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .teamStyle .media-body .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .teamStyle .media-body .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .teamStyle .media-body .list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.teachersPhoto {
  width: 100%;
}

.teachersPhoto img {
  width: 100%;
}

.teachersInfo h3 {
  color: #ea7066;
  text-transform: capitalize;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 28px;
  margin: 0 0 25px 0;
}

.teachersInfo p {
  margin: 0 0 25px 0;
}

.teachersInfo .list-inline {
  text-align: left;
}

@media (min-width: 320px) {
  .teachersInfo .list-inline {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .teachersInfo .list-inline {
    margin: 0 0 45px 0;
  }
}

@media (min-width: 768px) {
  .teachersInfo .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .teachersInfo .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .teachersInfo .list-inline li {
    padding: 0 5px;
  }
}

.teachersInfo .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
}

.teachersInfo .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .teachersInfo .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .teachersInfo .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .teachersInfo .list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.teachersProfession, .professionDetails {
  width: 100%;
  height: 50px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  text-indent: 30px;
  line-height: 50px;
  font-weight: 600;
}

.teachersProfession {
  color: #ffffff;
  text-transform: uppercase;
}

.professionDetails {
  background-color: #ffffff;
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: capitalize;
}

/*=== 5.8 EVENT SECTION ===*/
.eventSection {
  padding-bottom: 70px;
}

.eventContent {
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  margin-bottom: 30px;
}

.eventContent .media-left {
  border-radius: 13px 0 0 13px;
  position: relative;
  width: 100%;
  display: block;
  padding-right: 0;
}

@media (min-width: 992px) {
  .eventContent .media-left {
    float: left;
    width: 50%;
    display: inline-block;
    padding-right: 10px;
  }
}

.eventContent .media-left img {
  width: 100%;
  box-shadow: none;
}

@media (min-width: 768px) {
  .eventContent .media-left img {
    -webkit-box-shadow: 4px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 4px 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 4px 0 0 rgba(0, 0, 0, 0.1);
  }
}

.eventContent .media-body h3 a {
  color: #ffffff;
}

.eventContent .media-body .list-unstyled li {
  color: #ffffff;
}

.eventContent .media-body p {
  color: #ffffff;
  margin-bottom: 25px;
}

@media (min-width: 320px) {
  .eventContent .media-body {
    width: 100%;
    display: block;
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .eventContent .media-body {
    padding: 14px 10px 10px 10px;
    display: inline-block;
    float: left;
    width: 50%;
    overflow: visible;
  }
}

@media (min-width: 1200px) {
  .eventContent .media-body {
    width: auto;
    display: table-cell;
    padding: 20px 10px 0 20px;
    width: 50%;
  }
}

.eventContent .list-inline a {
  font-size: 14px;
}

.eventContent .list-inline .btn-primary {
  background-color: #ffffff;
  color: #666666;
  font-weight: 600;
  padding: 8px 15px;
}

.eventContent .list-inline .btn-primary:hover {
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-yellow .btn-primary:hover {
  color: #f0c24b;
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-green .btn-primary:hover {
  color: #b5d56a;
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-red .btn-primary:hover {
  color: #ea7066;
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-sky .btn-primary:hover {
  color: #84bed6;
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-purple .btn-primary:hover {
  color: #a597e7;
  background-color: #ffffff !important;
}

.eventContent .list-inline.btn-pink .btn-primary:hover {
  color: #ea77ad;
  background-color: #ffffff !important;
}

.sticker-round {
  width: 70px;
  padding: 10px 0;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  background-color: #84bed6;
  display: block;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
}

/*=== 5.9 COUNT UP SECTION ===*/
.countUpSection {
  background-image: url("../img/home/countup.jpg");
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center !important;
  background-attachment: fixed;
  color: #ffffff;
  padding: 80px 0;
}

.countUpSection .counter {
  width: 145px;
  height: 145px;
  line-height: 145px;
  display: inline-block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 4px solid #ffffff;
}

@media (min-width: 320px) {
  .countUpSection .counter {
    font-size: 32px;
    display: block;
    margin: 0 auto 25px auto;
  }
}

@media (min-width: 768px) {
  .countUpSection .counter {
    font-size: 52px;
    display: inline-block;
    margin: 0 0 25px 0;
  }
}

.countUpSection .counterInfo {
  line-height: 40px;
  height: 40px;
  border: none;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ea7066;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  width: 129px;
  display: inline-block;
}

@media (min-width: 320px) {
  .countUpSection .counterInfo {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .countUpSection .counterInfo {
    font-size: 15px;
  }
}

@media (min-width: 320px) {
  .countUpSection .text-center {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .countUpSection .text-center {
    margin-bottom: 0;
  }
}

/*=== 5.10 HOME GALLERY SECTION ===*/
.filter-container {
  padding: 0 20px 30px 20px;
}

@media (min-width: 320px) {
  .isotopeFilters ul.filter > li {
    padding: 6px 2px;
  }
}

@media (min-width: 768px) {
  .isotopeFilters ul.filter > li {
    padding: 0 2px;
  }
}

.isotopeFilters ul.filter > li a {
  padding: 0 25px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeFilters ul.filter > li.active a {
  background-color: #ea7066;
  color: #ffffff;
}

.fancybox-lock .fancybox-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.isotopeSelector img {
  width: 100%;
}

.isotopeSelector:hover img {
  -moz-transform: scale(1.2) rotate(0);
  -o-transform: scale(1.2) rotate(0);
  -ms-transform: scale(1.2) rotate(0);
  -webkit-transform: scale(1.2) rotate(0);
  transform: scale(1.2) rotate(0);
}

.isotopeSelector figure {
  position: relative;
  margin-bottom: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector .overlay-background {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector .overlay .fancybox-pop {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
}

.isotopeSelector .overlay .fancybox-pop i {
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid #ffffff;
  color: #ffffff;
  line-height: 35px;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.isotopeSelector:hover .overlay {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.isotopeSelector:hover .overlay .overlayInfo {
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.isotopeSelector:hover h4 {
  color: #ea7066;
}

.btnArea {
  width: 100%;
  display: block;
  float: left;
  margin-top: 20px;
  text-align: center;
}

.btnArea .btn-primary {
  display: inline-block;
}

/*=== 5.11 COMMENT SECTION ===*/
.commentSection {
  background-image: url("../img/home/pattern-light.png");
  width: 100%;
  background-repeat: repeat;
  height: auto;
}

@media (min-width: 320px) {
  .commentContent {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .commentContent {
    padding: 0 100px;
  }
}

.commentContent i {
  font-size: 130px;
  color: #ffffff;
  margin-bottom: 40px;
}

.commentContent h2 {
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
  line-height: 36px;
  color: #ffffff;
  margin: 0 0 40px 0;
  text-transform: capitalize;
}

.commentContent h3 {
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0 0 12px 0;
  font-weight: 600;
}

.commentContent p {
  color: #ffffff;
  margin-bottom: 0;
}

/*=== 5.12 MAP AREA SECTION ===*/
.mapArea {
  width: 100%;
  display: block;
}

.mapArea #map {
  width: 100%;
  height: 420px;
}

/*=== 5.13 HOME CONTACT SECTION ===*/
.newsSection .block .list-unstyled li,
.newsSection .block .list-inline li {
  display: inline-block;
}

.homeContactContent h2 {
  font-size: 36px;
  line-height: 4px1;
  color: #ea7066;
  text-transform: capitalize;
  font-family: 'Dosis', sans-serif;
  margin: 0 0 20px 0;
}

.homeContactContent p {
  font-size: 15px;
  color: #222222;
  margin: 0 0 15px 0;
}

.homeContactContent address > p {
  color: rgba(34, 34, 34, 0.65);
}

.homeContactContent address > p > i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
}

.homeContactContent address > p > a {
  color: rgba(34, 34, 34, 0.65);
}

.homeContactContent .form-group {
  position: relative;
}

.homeContactContent .form-group .input-group-addon {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #888888;
  border: none;
  background-color: transparent;
}

.homeContactContent .form-group i {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #888888;
}

.homeContactContent .form-group .form-control {
  position: relative;
  padding-right: 50px;
  height: 50px;
  border-width: 3px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #222222;
  background-color: transparent;
}

.homeContactContent .form-group textarea.form-control {
  height: 147px;
  line-height: 30px;
}

@media (min-width: 320px) {
  .homeContactContent form .btn-primary {
    float: left;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .homeContactContent form .btn-primary {
    float: right;
    width: auto;
  }
}

.eventSidebar .homeContactContent .form-group {
  display: block;
}

.eventSidebar .homeContactContent .form-group i {
  left: -5px;
}

.eventSidebar .homeContactContent .form-group .form-control {
  padding-left: 30px;
}

.addressContent {
  margin-bottom: 25px;
}

.addressContent .media-left {
  width: 85px;
  height: 85px;
  line-height: 85px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 30px;
  padding-right: 0;
  color: #ffffff;
}

@media (min-width: 320px) {
  .addressContent .media-left {
    display: inline-block;
    margin: 0 10px 6px 0;
    float: left;
  }
}

@media (min-width: 768px) {
  .addressContent .media-left {
    display: block;
    margin: 0 10px 6px 0;
    float: none;
  }
}

@media (min-width: 992px) {
  .addressContent .media-left {
    display: inline-block;
    margin: 0 10px 6px 0;
    float: left;
  }
}

.addressContent .media-body .media-heading {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 16px;
  color: #202020;
}

.addressContent .media-body p {
  font-weight: 600;
}

.addressContent .media-body p a {
  color: #666666;
}

.areaPadding {
  padding: 90px 0 100px 0;
}

.homeContactContent .formBtnArea {
  text-align: center;
}

@media (min-width: 320px) {
  .homeContactContent .formBtnArea .btn-primary {
    float: left;
    display: block;
  }
}

@media (min-width: 768px) {
  .homeContactContent .formBtnArea .btn-primary {
    float: none;
    display: inline-block;
  }
}

.sectionTitle h2 span.shape {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
  display: inline-block;
  padding: 0;
  position: relative;
  float: none;
}

.sectionTitle h2 span.shape:after, .sectionTitle h2 span.shape:before {
  content: "";
  height: 2px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  border-radius: 2px;
}

.sectionTitle h2 span.shape:after {
  content: "";
  width: 70px;
  top: 6px;
}

.sectionTitle h2 span.shape:before {
  content: "";
  width: 55px;
  top: 11px;
}

.sectionTitle h2 span.shape-left:before,
.sectionTitle h2 span.shape-left:after {
  right: 3px;
}

.sectionTitle h2 span.shape-right:before,
.sectionTitle h2 span.shape-right:after {
  left: 3px;
}

.sectionTitle h2 span.bg-color-4,
.sectionTitle h2 span.bg-color-4:after,
.sectionTitle h2 span.bg-color-4:before {
  background-color: #9fccdf !important;
}

.sectionTitle h2 span.bg-color-3,
.sectionTitle h2 span.bg-color-3:after,
.sectionTitle h2 span.bg-color-3:before {
  background-color: #ee8d85 !important;
}

.footer-2 .footerInfoArea {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-2 .footerInfoArea, .footer-2 .copyRight {
  background: #f8f8f8;
}

.footer-2 .footerInfo p,
.footer-2 .footerList > li > a,
.footer-2 .postLink li .media-body .media-heading a,
.footer-2 .copyRightText p {
  color: #666666;
}

.footer-2 .footerInfo .input-group .form-control {
  background-color: #ffffff;
}

.footer-2 .footerInfo .input-group {
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footer-2 .footerInfo .input-group:hover {
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
}

.footer-2 .copyRightText p a {
  color: #ea7066;
}

/*=== 5.1 BANNER ===*/
.bannercontainerV2 .tp-caption.rs-caption-1,
.bannercontainerV2 .tp-caption.rs-caption-2,
.bannercontainerV2 .tp-caption.rs-caption-3 {
  width: 90%;
}

@media (min-width: 480px) {
  .bannercontainerV2 .tp-caption.rs-caption-1,
  .bannercontainerV2 .tp-caption.rs-caption-2,
  .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .bannercontainerV2 .tp-caption.rs-caption-1,
  .bannercontainerV2 .tp-caption.rs-caption-2,
  .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .bannercontainerV2 .tp-caption.rs-caption-1,
  .bannercontainerV2 .tp-caption.rs-caption-2,
  .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 50%;
  }
}

.bannercontainerV2 .tp-caption.rs-caption-1 {
  color: #ffffff;
  line-height: 55px !important;
}

@media (min-width: 320px) {
  .bannercontainerV2 .tp-caption.rs-caption-1 br {
    display: none;
  }
}

@media (min-width: 992px) {
  .bannercontainerV2 .tp-caption.rs-caption-1 br {
    display: block;
  }
}

@media (min-width: 320px) {
  .bannercontainerV2 .tp-caption.rs-caption-1 span {
    color: #ffd86c !important;
  }
}

.bannercontainerV2 .tp-caption.rs-caption-2 {
  color: #ffffff;
}

@media (min-width: 320px) {
  .bannercontainerV2 .tp-caption.rs-caption-2 {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) {
  .bannercontainerV2 .tp-caption.rs-caption-2 {
    font-size: 20px !important;
  }
}

@media (min-width: 320px) {
  .bannercontainerV2 .tp-caption.rs-caption-3 .btn-default {
    padding: 6px 12px !important;
    font-size: 14px;
    background-color: #ffffff !important;
    color: #e7655a !important;
    font-weight: 900;
  }
}

@media (min-width: 768px) {
  .bannercontainerV2 .tp-caption.rs-caption-3 .btn-default {
    font-size: 16px;
    padding: 11px 50px !important;
  }
}

@media (min-width: 992px) {
  .bannercontainerV2 .tp-caption.rs-caption-3 .btn-default {
    font-size: 18px;
  }
}

/*=== 5.2.BANNER BOTTOM ===*/
.banner-bottom {
  width: 100%;
  float: left;
}

.banner-bottom-inner {
  position: relative;
  z-index: 500;
  width: 100%;
  float: left;
}

@media (min-width: 480px) {
  .banner-bottom-inner {
    top: -30px;
  }
}

@media (min-width: 768px) {
  .banner-bottom-inner {
    top: -90px;
  }
}

.banner-bottom-inner .colContent {
  position: relative;
}

.banner-bottom-inner .colContent img {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  display: block;
}

.banner-bottom-inner .colContent-info {
  position: absolute;
}

.banner-bottom-inner .colContent-info h3 {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  line-height: 34px;
  padding: 0 10px;
  display: inline-block;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .banner-bottom-inner .colContent-info h3 {
    font-size: 30px;
    line-height: 44px;
    padding: 0 25px;
  }
}

.banner-bottom-inner .colContent-info p {
  font-size: 12px;
  color: #666666;
  margin: 0;
  padding: 7px 10px;
  width: 90%;
  background: #f2f2f2;
}

@media (min-width: 992px) {
  .banner-bottom-inner .colContent-info p {
    font-size: 14px;
    width: 75%;
    padding: 7px 25px;
  }
}

.banner-bottom-inner .colContent-info.text-left {
  top: 25px;
}

@media (min-width: 992px) {
  .banner-bottom-inner .colContent-info.text-left {
    top: 55px;
  }
}

.banner-bottom-inner .colContent-info.text-left h3, .banner-bottom-inner .colContent-info.text-left p {
  border-radius: 0 6px 6px 0;
}

.banner-bottom-inner .colContent-info.text-center {
  bottom: 0;
  left: 20%;
}

.banner-bottom-inner .colContent-info.text-center h3 {
  border-radius: 6px;
}

.banner-bottom-inner .colContent-info.text-center p {
  margin: 0 auto;
  border-radius: 6px 6px 0 0;
}

.banner-bottom-inner .colContent-info.text-right {
  right: 0;
  top: 25px;
}

@media (min-width: 992px) {
  .banner-bottom-inner .colContent-info.text-right {
    top: 55px;
  }
}

.banner-bottom-inner .colContent-info.text-right h3, .banner-bottom-inner .colContent-info.text-right p {
  border-radius: 6px 0 0 6px;
}

.banner-bottom-inner .colContent-info.text-right p {
  margin-right: 0;
  margin-left: auto;
}

/*=== 5.3 PRODUCT SECTION ===*/
.promotionWrapper .promotionInfo .btn-default {
  background-color: #ffffff;
  color: #ea7066 !important;
  padding: 13px 28px !important;
  font-weight: 700;
}

.promotionWrapper .promotionInfo .btn-default > i {
  margin-right: 10px;
  padding-left: 7px;
}

/*=== 5.3 PRODUCT SECTION ===*/
@media (max-width: 480px) {
  .productSection .col-xs-6 {
    width: 100%;
  }
}

.box {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.box-img {
  border-radius: 12px 12px 0 0;
  border: 4px solid transparent;
  background: #ffffff;
  padding: 30px 0;
  position: relative;
}

.box-img img {
  border-radius: 12px 12px 0 0;
  display: inline-block;
  margin: 0 auto;
  max-height: 132px;
}

.box-img .btn-block {
  position: absolute;
  bottom: 0;
  height: 55px;
  line-height: 55px;
  color: #ffffff;
  background: #ea7066;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transform: translate(0%, 50%);
  -o-transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  -webkit-transform: translate(0%, 50%);
  transform: translate(0%, 50%);
  z-index: -1;
}

.box-img .btn-block i {
  margin-right: 7px;
}

.box:hover .box-img .btn-block {
  -moz-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.box-info {
  padding: 30px;
}

.box-info h4 {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 700;
}

.box-info h4 a {
  color: #ffffff;
}

.box-info .btn-primary {
  font-size: 14px;
  background-color: #ffffff;
  color: #666666;
  font-weight: 600;
}

.box-info .btn-primary:hover {
  background-color: #ffffff;
}

.price span {
  font-size: 27px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 600;
  display: inline-block;
}

.price span del {
  color: rgba(255, 255, 255, 0.7);
}

.box .sticker-round {
  width: auto;
  padding: 10px;
  left: 7px;
  top: 7px;
  font-size: 13px;
}

.productSection .tabCommon .tab-pane {
  border: 0;
  border-radius: 0;
}

/*=== 5.4 BRAND SECTION ===*/
.brandSection {
  margin-bottom: 90px;
}

.brandSection .owl-carousel .owl-item {
  height: 150px;
}

.brandSection .partnersLogoSlider .slide {
  display: table;
  width: 200px;
  margin: 0 auto;
  height: 145px;
  background: #f8f8f8;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

@media (min-width: 768px) {
  .brandSection .partnersLogoSlider .slide {
    width: 100%;
  }
}

.brandSection .partnersLogoSlider .slide .partnersLogo {
  display: table-cell;
  vertical-align: middle;
  text-align: center !important;
  width: 200px;
}

.brandSection .partnersLogoSlider .slide .partnersLogo img {
  width: auto;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .brandSection .owl-nav {
    display: none;
  }
}

.headerV3 {
  position: absolute;
  width: 100%;
  top: 0;
}

.headerV3 .lightHeader {
  background-color: rgba(47, 60, 67, 0.502);
  box-shadow: none;
}

.headerV3 #menuBar.menu-sm {
  background-color: rgba(0, 0, 0, 0.8);
}

.headerV3 .navbar-secondary .navbar-nav li a span {
  color: #ffffff !important;
}

.headerV3 .navbar-secondary .navbar-nav li.active a span {
  color: #f0c24b !important;
}

@media (min-width: 768px) {
  .headerV3 .lightHeader .navbar-nav > li > a {
    color: #ffffff;
  }
}

@media (min-width: 768px) {
  .headerV3 .navbar-right .dropdown-menu-left {
    right: auto;
    left: -50%;
  }
}

@media (min-width: 768px) {
  .headerV3 .navbar-right .dropdown-menu-right {
    left: auto;
    right: -50%;
  }
}

.version3 #menuBar.bb-fixed-header {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 992px) {
  .version3 #menuBar.bb-fixed-header {
    height: 90px !important;
  }
}

@media (min-width: 768px) {
  .version3 .lightHeader .navbar-nav > li > a {
    color: #ffffff;
  }
}

@media (min-width: 768px) {
  .navbar-secondary.lightHeader {
    height: 75px !important;
  }
}

@media (min-width: 992px) {
  .navbar-secondary.lightHeader {
    height: 110px !important;
  }
}

@media (min-width: 320px) {
  .navbar-secondary .navbar-header .navbar-brand {
    height: auto;
    padding: 15px;
    width: 160px;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-header .navbar-brand {
    height: 110px;
    padding: 30px 15px;
    width: 196px;
  }
}

@media (min-width: 320px) {
  .navbar-secondary .navbar-nav > li.dropdown.open > a:after {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-secondary .navbar-nav > li.dropdown.open > a:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    margin-left: -10px;
    content: "";
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 86px;
  }
}

.navbar-secondary .navbar-nav > li.color-1.dropdown a:after {
  border-bottom: 7px solid #f0c24b !important;
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu > a:after {
  border-bottom: 7px solid #b5d56a !important;
}

.navbar-secondary .navbar-nav > li.color-3.dropdown a:after {
  border-bottom: 7px solid #ea7066 !important;
}

.navbar-secondary .navbar-nav > li.color-4.dropdown a:after {
  border-bottom: 7px solid #84bed6 !important;
}

.navbar-secondary .navbar-nav > li.color-5.dropdown a:after {
  border-bottom: 7px solid #a597e7 !important;
}

.navbar-secondary .navbar-nav > li.color-6.dropdown a:after {
  border-bottom: 7px solid #ea77ad !important;
}

.navbar-secondary .navbar-nav > li.color-1 > a:hover span {
  color: #f0c24b !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu {
    border-top: 4px solid #f0c24b !important;
  }
}

.navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li a:hover {
  background-color: #f0c24b !important;
  color: #ffffff !important;
}

.navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active > a, .navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active li.active a {
  color: #f0c24b !important;
}

.navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active a:hover, .navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li.active li.active a:hover {
  color: #ffffff !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-1.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #f0c24b !important;
  }
}

.navbar-secondary .navbar-nav > li.color-1.singleDrop.open a > span {
  color: #f0c24b !important;
}

.navbar-secondary .navbar-nav > li.color-3 > a:hover span {
  color: red !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-3.singleDrop > .dropdown-menu {
    border-top: 4px solid #ea7066 !important;
  }
}

.navbar-secondary .navbar-nav > li.color-3.singleDrop > .dropdown-menu li a:hover {
  background-color: #ea7066 !important;
}

.navbar-secondary .navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active > a, .navbar-secondary .navbar-nav > li.color-3.singleDrop > .dropdown-menu li.active li.active a {
  color: #ea7066 !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-3.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #ea7066 !important;
  }
}

.navbar-secondary .navbar-nav > li.color-3.singleDrop.open a > span {
  color: #ea7066 !important;
}

.navbar-secondary .navbar-nav > li.color-2 > a:hover span {
  color: #b5d56a !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu {
    border-top: 4px solid #b5d56a !important;
  }
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  color: #b5d56a !important;
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover {
  background-color: #b5d56a !important;
  color: #ffffff !important;
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active > a, .navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active li.active a {
  color: #b5d56a !important;
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active a:hover, .navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.active li.active a:hover {
  color: #ffffff !important;
}

.navbar-secondary .navbar-nav > li.color-2.dropdown.megaDropMenu .open a > span {
  color: #b5d56a !important;
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-4.singleDrop > .dropdown-menu {
    border-top: 4px solid #84bed6 !important;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-4.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #84bed6 !important;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-5.singleDrop > .dropdown-menu {
    border-top: 4px solid #a597e7 !important;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-5.singleDrop > .dropdown-menu li .dropdown-menu {
    border-left: 4px solid #a597e7 !important;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.color-6.singleDrop > .dropdown-menu {
    border-top: 4px solid #ea77ad !important;
  }
}

.navbar-secondary .navbar-nav {
  padding-top: 15px;
}

.navbar-secondary .navbar-nav .dropdown-menu {
  border: none !important;
}

.navbar-secondary .navbar-nav li a i {
  display: inline-block !important;
}

.navbar-secondary .navbar-nav li a span {
  border-right: none !important;
  display: inline-block !important;
  text-align: left;
  padding-left: 5px !important;
  width: auto;
  padding-right: 24px;
  color: #fff;
}

.navbar-secondary .navbar-nav li:last-child a span {
  padding-right: 0px;
}

@media (min-width: 768px) {
  .navbar-secondary.bb-fixed-header .navbar-nav > li.dropdown.open > a:after {
    top: 66px;
  }
}

@media (max-width: 990px) and (min-width: 480px) {
  .navbar-secondary .dropdown-submenu > .dropdown-menu {
    left: 0;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .dropdown-submenu > .dropdown-menu {
    left: 100%;
  }
}

@media (max-width: 990px) {
  .navbar-secondary .navbar-nav {
    height: 340px;
    overflow-x: hidden;
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease;
    margin: 0 0 0 -15px;
    padding: 15px;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-nav {
    height: auto;
    overflow-x: visible;
    overflow-y: visible;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-nav > li a {
    color: #000000;
    padding: 5px 12px !important;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-nav > li a {
    margin: 0;
    padding: 23px 0;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-collapse {
    -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 990px) and (min-width: 768px) {
  .navbar-secondary .navbar-collapse {
    border-color: #ffffff;
    box-shadow: none;
  }
}

@media (max-width: 990px) {
  .navbar-secondary .navbar-collapse li.dropdown .dropdown-menu {
    box-shadow: none;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-collapse li.dropdown .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}

@media (max-width: 990px) {
  .navbar-secondary .navbar-collapse li.dropdown.open .dropdown-menu {
    display: block;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

@media (max-width: 990px) {
  .navbar-secondary .navbar-collapse li.dropdown.dropdown-submenu.open .dropdown-menu {
    display: block;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-nav > li.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
    display: none;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.dropdown.singleDrop .dropdown-menu .dropdown-menu:after {
    display: inline-block;
  }
}

@media (max-width: 990px) and (min-width: 320px) {
  .navbar-secondary .navbar-nav > li.dropdown.open > a:after {
    display: none;
  }
}

@media (max-width: 990px) and (min-width: 992px) {
  .navbar-secondary .navbar-nav > li.dropdown.open > a:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    margin-left: -10px;
    content: "";
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 102px;
  }
}

@media (max-width: 990px) and (min-width: 768px) {
  .navbar-secondary .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0%;
  }
}

@media (max-width: 990px) and (min-width: 768px) {
  .navbar-secondary .navbar-right .dropdown-menu-right {
    left: auto;
    right: 0%;
  }
}

@media (max-width: 990px) {
  .navbar-secondary .navbar-header {
    float: none;
  }
  .navbar-secondary .navbar-left, .navbar-secondary .navbar-right {
    float: none !important;
  }
  .navbar-secondary .navbar-toggle {
    display: block;
  }
  .navbar-secondary .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-secondary .navbar-nav {
    float: none !important;
    background-color: #fff !important;
  }
  .navbar-secondary .navbar-nav > li {
    float: none;
  }
  .navbar-secondary .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-secondary .collapse.in {
    display: block !important;
  }
}

/*=== 5.1 BANNER ===*/
.bannercontainerV3 {
  width: 100%;
  position: relative;
  padding: 0;
}

.bannercontainerV3 .tp-caption.rs-caption-1 span {
  color: #ffd86c !important;
}

.bannercontainerV3 .tp-caption.rs-caption-3 .btn-default {
  padding: 8px 20px !important;
  background-color: #ffffff !important;
  color: #e7655a !important;
  font-size: 14px;
}

@media (min-width: 992px) {
  .bannercontainerV3 .tp-caption.rs-caption-3 .btn-default {
    padding: 12px 25px !important;
    font-weight: 900;
    font-size: 18px;
  }
}

.courseSidebar .panel-body .input-group .input-group-addon .bg-color-1 {
  border: none !important;
}

.courseList
.media-body
.list-inline.btnPart.btn-yellow > li a.btn-primary:hover {
  background-color: #f0c24b !important;
  border: none !important;
}

.priceRange .progress .progress-bar,
.priceRange .progress .bulet,
.courseList .media-body .list-inline.btnPart.btn-green > li a.btn-primary:hover {
  background-color: #b5d56a !important;
  border: none !important;
}

.courseList .media-body
.list-inline.btnPart.btn-sky > li a.btn-primary:hover {
  background-color: #84bed6 !important;
  border: none !important;
}

.tableVertical .priceBtn.alt .btn-primary {
  background-color: #ffffff !important;
  color: #666666 !important;
  border: none !important;
}

/*=== 5.4 ABOUT SCHOOL ===*/
@media (min-width: 320px) {
  .aboutSchool {
    padding: 50px !important;
  }
}

@media (min-width: 768px) {
  .aboutSchool {
    padding: 100px 0px 0px 0px !important;
  }
}

.aboutSchool .schoolInfo h2 {
  text-transform: uppercase;
  color: #e7655a;
  font-size: 30px;
}

.aboutSchool .schoolInfo h2 span {
  color: #666666;
  font-size: 38px;
}

.aboutSchool .schoolInfo p {
  color: #222222 !important;
  font-weight: 600;
  font-size: 18px;
}

.aboutSchool .schoolInfo .aboutP2 {
  color: #666666 !important;
  font-weight: 600;
  font-size: 18px;
}

@media (min-width: 992px) {
  .version4 #menuBar.bb-fixed-header {
    height: 90px !important;
  }
}

.version4 .navbar-secondary.secondary-light .navbar-nav li a span {
  color: #000000;
}

.version4 .navbar-secondary.secondary-light .navbar-nav li.active a span {
  color: #f0c24b;
}

.version4 .bannercontainerV2 .tp-caption.rs-caption-1,
.version4 .bannercontainerV2 .tp-caption.rs-caption-2,
.version4 .bannercontainerV2 .tp-caption.rs-caption-3 {
  width: 90%;
}

@media (min-width: 480px) {
  .version4 .bannercontainerV2 .tp-caption.rs-caption-1,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-2,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .version4 .bannercontainerV2 .tp-caption.rs-caption-1,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-2,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .version4 .bannercontainerV2 .tp-caption.rs-caption-1,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-2,
  .version4 .bannercontainerV2 .tp-caption.rs-caption-3 {
    width: 50%;
  }
}

.version4 .bannercontainerV2 .tp-caption.rs-caption-1 {
  color: #222222;
}

.version4 .bannercontainerV2 .tp-caption.rs-caption-2 {
  color: #666666;
}

.padding-LR-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.categoris .box .box-img {
  background-color: transparent !important;
  overflow: hidden;
  border-radius: 10px !important;
  height: 240px !important;
}

.categoris .box .box-img img {
  margin-top: 13px;
  max-height: 175px;
}

.categoris .box .box-info {
  padding-bottom: 25px !important;
}

.categoris .box .box-info h4 {
  margin-bottom: 0px !important;
}

.bg-gray {
  background-color: #f8f8f8;
}

@media (min-width: 320px) {
  .single-category {
    padding: 50px 0 !important;
  }
}

@media (min-width: 992px) {
  .single-category {
    padding: 100px 0 !important;
  }
}

.single-category .single-cat-border {
  border: 1px solid rgba(222, 222, 222, 0.5);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 20px 0px 20px !important;
  border-radius: 5px;
  overflow: hidden;
}

.single-category .category-menu .category-title {
  text-align: left;
  color: #ffffff;
  padding: 17px;
  border-radius: 5px;
  background: #f0c24b;
  text-transform: capitalize;
  font-size: 24px;
}

.single-category .category-menu ul {
  text-align: left !important;
  padding: 20px;
}

.single-category .category-menu ul li {
  padding: 5px 0;
}

.single-category .category-menu ul li a {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}

.single-category .category-menu.color-1 ul li a:hover {
  color: #f0c24b;
}

.single-category .category-menu.color-2 ul li a:hover {
  color: #b5d56a;
}

.single-category .category-menu.color-3 ul li a:hover {
  color: #ea7066;
}

.single-category .category-menu.color-4 ul li a:hover {
  color: #84bed6;
}

.single-category .category-Slider .owl-carousel .owl-controls .owl-nav {
  padding: 0 10px;
}

.single-category .category-Slider .single-item {
  position: relative;
}

@media (min-width: 768px) {
  .single-category .category-Slider .single-item img {
    height: 414px;
  }
}

@media (min-width: 992px) {
  .single-category .category-Slider .single-item img {
    height: 564px;
  }
}

@media (min-width: 1200px) {
  .single-category .category-Slider .single-item img {
    height: 605px;
  }
}

@media (min-width: 320px) {
  .single-category .category-Slider .single-item .slide-cat-info {
    position: absolute;
    bottom: 10px;
    left: 50%;
    padding: 10px 20px;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
  }
}

@media (min-width: 768px) {
  .single-category .category-Slider .single-item .slide-cat-info {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 1;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 217px;
    opacity: .890;
    padding: 20px;
    border-radius: 5px;
  }
}

@media (min-width: 320px) {
  .single-category .category-Slider .single-item .slide-cat-info h2 {
    color: #ffffff !important;
    font-size: 14px !important;
    text-transform: capitalize;
    margin: 0 !important;
  }
}

@media (min-width: 768px) {
  .single-category .category-Slider .single-item .slide-cat-info h2 {
    font-size: 20px !important;
  }
}

@media (min-width: 320px) {
  .single-category .category-Slider .single-item .slide-cat-info h2 span {
    font-size: 18px !important;
    display: block;
  }
}

@media (min-width: 768px) {
  .single-category .category-Slider .single-item .slide-cat-info h2 span {
    font-size: 25px !important;
  }
}

.single-category .box {
  box-shadow: none !important;
  border-radius: 7px !important;
  margin-bottom: 10px !important;
}

.single-category .box .border-color-1 {
  border: 2px solid transparent;
}

.single-category .box .border-color-2 {
  border: 2px solid transparent;
}

.single-category .box .border-color-3 {
  border: 2px solid transparent;
}

.single-category .box .border-color-4 {
  border: 2px solid transparent;
}

@media (min-width: 320px) {
  .single-category .box .box-img {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    height: 126px !important;
    padding: 0 !important;
    background-color: #fcfcfc;
  }
}

@media (min-width: 992px) {
  .single-category .box .box-img {
    height: 170px !important;
  }
}

@media (min-width: 1200px) {
  .single-category .box .box-img {
    height: 209px !important;
  }
}

@media (min-width: 320px) {
  .single-category .box .box-img img {
    max-height: 122px !important;
  }
}

@media (min-width: 768px) {
  .single-category .box .box-img img {
    max-height: 125px !important;
    width: 150px;
  }
}

@media (min-width: 992px) {
  .single-category .box .box-img img {
    max-height: 166px !important;
    width: 181px;
  }
}

@media (min-width: 1200px) {
  .single-category .box .box-img img {
    max-height: 206px !important;
    width: 181px;
  }
}

@media (min-width: 320px) {
  .single-category .box .box-info {
    padding: 5px !important;
  }
}

@media (min-width: 768px) {
  .single-category .box .box-info {
    padding: 10px !important;
  }
}

@media (min-width: 992px) {
  .single-category .box .box-info {
    padding: 16px !important;
  }
}

@media (min-width: 320px) {
  .single-category .box .box-info h4 {
    font-size: 14px !important;
    font-weight: 700;
    margin: 0 !important;
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) {
  .single-category .box .box-info h4 {
    font-size: 14px !important;
  }
}

@media (min-width: 992px) {
  .single-category .box .box-info h4 {
    font-size: 18px !important;
  }
}

@media (min-width: 320px) {
  .single-category .box .box-info h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    margin-top: 8px;
    color: #ffffff;
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .single-category .box .box-info h3 {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .single-category .box .box-info h3 {
    font-size: 23px;
    margin-top: 12px;
    margin-bottom: 0 !important;
  }
}

/*================= 6. COURSE ==================*/
/*=== 6.1 COURSE LEFT SIDEBAR ===*/
.courseSidebar {
  border: none;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.courseSidebar .panel-heading {
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}

.courseSidebar .panel-heading .panel-title {
  font-size: 24px;
  color: #ffffff;
  text-transform: capitalize;
  font-family: 'Dosis', sans-serif;
}

.courseSidebar .panel-body {
  border: 1px solid #dedede;
  border-top: none;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.courseSidebar .panel-body > .input-group {
  border-bottom: 1px solid #dedede;
  margin: 20px 0 10px 0;
  padding-bottom: 10px;
}

.courseSidebar .panel-body > .input-group .form-control {
  height: 49px;
  border: none;
  padding: 0 12px 0 12px;
  color: #666666;
}

.courseSidebar .panel-body > .input-group .input-group-addon {
  background-color: transparent;
  padding: 0;
  border: none;
}

.courseSidebar .panel-body > .input-group .input-group-addon .btn-primary {
  width: 75px;
  padding: 10px;
}

.courseSidebar .panel-body .categoryItem li a {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: #666666;
  font-size: 14px;
  border: 1px solid #dedede;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  margin: 5px 0;
  text-indent: 20px;
  float: left;
}

.priceRange .progress {
  margin: 20px 0 25px 0;
  height: 4px;
  background-color: #252525;
  position: relative;
  overflow: visible;
}

.priceRange .progress .progress-bar {
  background-color: #b5d56a !important;
}

.priceRange .progress .bulet {
  width: 16px;
  height: 16px;
  background-color: #b5d56a !important;
  border: 2px solid #b5d56a !important;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  left: 0px;
  top: -5px;
  position: absolute;
}

.priceRange .progress .bulet.next {
  left: 60px;
}

.priceRange .priceLabel {
  float: right;
  line-height: 30px;
  color: #202020;
}

@media (min-width: 480px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .priceRange .priceLabel {
    font-size: 5px;
  }
}

@media (min-width: 992px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

.priceRange {
  position: relative;
  display: inline-block;
  width: 100%;
  float: left;
  margin-top: 20px;
}

.priceRange .price-slider-inner {
  display: block;
  margin: 15px 0 25px 0;
}

.priceRange .price-slider-inner .amount-wrapper {
  position: absolute;
  bottom: 14px;
  right: 0;
  font-size: 14px;
  width: 120px;
}

.priceRange .price-slider-inner .amount-wrapper input {
  background-color: transparent;
  border: 0;
  width: 28%;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  text-align: right;
}

.priceRange .price-slider-inner .ui-widget-content {
  background: none;
  border: 0;
  background-color: #eeeeee;
  height: 5px;
  clear: both;
  border-radius: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin: 0 5px 0 9px;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-range {
  background-color: #b5d56a;
  border: none;
  border-radius: 0;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-handle {
  border-radius: 50%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: none;
  border: 3px solid #b5d56a;
  background-color: #b5d56a;
  top: -6px;
  width: 16px;
  height: 16px;
  outline: none;
}

.priceRange .btn-primary {
  border: none !important;
}

/*=== 6.2 COURSE LIST ===*/
.courseList {
  background-color: #f8f8f8;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  border: none;
}

.courseList .media-left {
  position: relative;
  padding: 0;
  float: left;
}

@media (min-width: 320px) {
  .courseList .media-left {
    margin: 0 0 5px 0;
    border-right: none;
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .courseList .media-left {
    margin: 0 0 5px 0;
    border-right: none;
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .courseList .media-left {
    margin: 0 25px 0 0;
    display: inline-block;
    width: 50%;
  }
}

.courseList .media-left img {
  width: 100%;
}

.courseList .media-left .sticker {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 65px;
  height: 65px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 65px;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
}

@media (min-width: 320px) {
  .courseList .media-body {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .courseList .media-body {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .courseList .media-body {
    padding: 0;
  }
}

.courseList .media-body .media-heading a {
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 20px 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

@media (min-width: 320px) {
  .courseList .media-body .media-heading a {
    margin: 0 0 20px 0;
  }
}

@media (min-width: 768px) {
  .courseList .media-body .media-heading a {
    margin: 15px 0;
  }
}

.courseList .media-body .list-inline {
  margin-bottom: 15px;
}

.courseList .media-body .list-inline li {
  line-height: 21px;
  color: #666666;
  margin-bottom: 5px;
}

.courseList .media-body .list-inline li i {
  margin-right: 6px;
}

.courseList .media-body .list-inline.btnPart {
  margin-bottom: 0;
}

.courseList .media-body .list-inline.btnPart > li a.btn-primary {
  font-size: 14px;
  background-color: #ffffff !important;
  color: #666666;
  border: none !important;
}

.courseList .media-body .list-inline.btnPart > li a.btn-primary:hover {
  color: #ffffff;
}

.courseList .media-body .list-inline.btnPart > li a.btn-link {
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  text-decoration: underline !important;
  box-shadow: none !important;
}

.courseList .media-body .list-inline.btnPart.btn-yellow > li a.btn-primary:hover {
  background-color: #f0c24b !important;
}

.courseList .media-body .list-inline.btnPart.btn-yellow > li a.btn-link:hover {
  color: #f0c24b !important;
}

.courseList .media-body .list-inline.btnPart.btn-green > li a.btn-primary:hover {
  background-color: #b5d56a !important;
}

.courseList .media-body .list-inline.btnPart.btn-green > li a.btn-link:hover {
  color: #b5d56a !important;
}

.courseList .media-body .list-inline.btnPart.btn-red > li a.btn-primary:hover {
  background-color: #ea7066 !important;
}

.courseList .media-body .list-inline.btnPart.btn-red > li a.btn-link:hover {
  color: #ea7066 !important;
}

.courseList .media-body .list-inline.btnPart.btn-sky > li a.btn-primary:hover {
  background-color: #84bed6 !important;
}

.courseList .media-body .list-inline.btnPart.btn-sky > li a.btn-link:hover {
  color: #84bed6 !important;
}

.courseList .media-body .list-inline.btnPart.btn-purple > li a.btn-primary:hover {
  background-color: #a597e7 !important;
}

.courseList .media-body .list-inline.btnPart.btn-purple > li a.btn-link:hover {
  color: #a597e7 !important;
}

.courseList .media-body .list-inline.btnPart.btn-pink > li a.btn-primary:hover {
  background-color: #ea77ad !important;
}

.courseList .media-body .list-inline.btnPart.btn-pink > li a.btn-link:hover {
  color: #ea77ad !important;
}

@media (min-width: 320px) {
  .courseList.couresListPage .media-left {
    margin: 0 0 5px 0;
    border-right: none;
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .courseList.couresListPage .media-left {
    margin: 0 0 5px 0;
    border-right: none;
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .courseList.couresListPage .media-left {
    margin: 0 25px 0 0;
    display: inline-block;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .courseList.couresListPage .media-left {
    margin: 0 25px 0 0;
    display: inline-block;
    width: auto;
  }
}

@media (min-width: 992px) {
  .blog-full .courseList .media-left {
    width: 35%;
  }
}

@media (min-width: 992px) {
  .blog-full .courseList .media-body {
    padding-right: 20px;
  }
}

/*=== 6.3 COURSE RIGHT SIDE BAR ===*/
.rightSidebar .panel {
  border: 0;
  margin-bottom: 30px;
}

.rightSidebar .panel .panel-heading {
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}

.rightSidebar .panel .panel-heading .panel-title {
  font-size: 24px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  font-family: 'Dosis', sans-serif;
}

.rightSidebar .panel .panel-body {
  border: 1px solid #dedede;
  border-top: 0;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
  padding: 0;
}

.rightSidebar .panel .panel-body .media-list li {
  padding: 20px 0 20px 20px;
  border-bottom: 1px solid #dedede;
  margin: 0;
}

.rightSidebar .panel .panel-body .media-list li:last-child {
  border: 0;
}

.rightSidebar .panel .panel-body .media-list li .media-left.iconContent {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  float: left;
  padding: 0;
  margin-right: 20px;
  font-size: 16px;
}

.rightSidebar .panel .panel-body .media-list li .media-body.iconContent .media-heading {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.rightSidebar .panel .panel-body .media-list li .media-body.iconContent p {
  font-weight: 600;
  margin-bottom: 0;
}

.rightSidebar .panel .panel-body .media-list.blogListing li .media-heading a {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #666666;
}

.rightSidebar .panel .panel-body .media-list.blogListing li .media-heading a:hover {
  color: #a597e7 !important;
}

.rightSidebar > .btnPart {
  margin-bottom: 30px;
}

.rightSidebar > .btnPart .btn-primary {
  border: none !important;
}

.eventSidebar.panel {
  border: 0;
  margin-bottom: 30px;
}

.eventSidebar.panel .panel-heading {
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}

.eventSidebar.panel .panel-heading .panel-title {
  font-size: 24px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  font-family: 'Dosis', sans-serif;
}

.eventSidebar.panel .panel-body {
  border: 1px solid #dedede;
  border-top: 0;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.eventSidebar.panel .panel-body .media-list li {
  padding: 10px 0;
  margin: 0;
}

.eventSidebar.panel .panel-body .media-list li .media-left.iconContent {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  float: left;
  padding: 0;
  margin-right: 20px;
  font-size: 16px;
}

.eventSidebar.panel .panel-body .media-list li .media-body.iconContent .media-heading {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.eventSidebar.panel .panel-body .media-list li .media-body.iconContent p {
  font-weight: 600;
  margin-bottom: 0;
}

.eventSidebar.panel .panel-body .media-list li .btn-primary {
  border: none !important;
}

.eventSidebar.panel .panel-body .media-list.blogListing li .media-heading a {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #666666;
}

.eventSidebar.panel .panel-body .media-list.blogListing li .media-heading a:hover {
  color: #a597e7 !important;
}

/*=== 6.4 COURSE COUNTER ===*/
.coursesCounter {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 330px;
  overflow: hidden;
  background: url(../img/course-single/course-count.jpg) no-repeat center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 10px;
}

.coursesCounter .counterInner {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #ffffff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  text-align: center;
}

.coursesCounter .counterInner h3 {
  font-family: 'Dosis', sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  margin: 40px 0;
}

.coursesCounter .counterInner > .btn-primary {
  background-color: #ffffff !important;
  border: none !important;
  color: #84bed6 !important;
}

.coursesCountStart {
  padding: 0 0 35px 0;
}

.coursesCountStart .courseCountTimer {
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
}

.coursesCountStart .courseCountTimer .table-cell {
  display: inline-block;
  margin: 0 5px;
}

.coursesCountStart .courseCountTimer .table-cell .tab-val {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
}

.coursesCountStart .courseCountTimer .table-cell .tab-metr {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
}

/*=== 7. BLOG ===*/
.singleBlog p, .singleBlog .blogInfo {
  margin-bottom: 30px !important;
}

.thumbnailContent > .caption.singleBlog h3 {
  line-height: 34px;
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  margin: 0 0 20px 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: block;
}

.socailLink.list-inline {
  text-align: left;
}

@media (min-width: 768px) {
  .socailLink.list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .socailLink.list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .socailLink.list-inline li {
    padding: 0 5px;
  }
}

.socailLink.list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff !important;
}

.socailLink.list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .socailLink.list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .socailLink.list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .socailLink.list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.socailLink.list-inline li a i {
  margin: 0 !important;
}

.blogComments {
  background-color: #f8f8f8;
  padding: 15px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  margin-bottom: 20px;
}

.blogComments h3 {
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  color: #ea7066;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.commentsList .media {
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #dedede;
}

.commentsList li:last-child {
  border: none;
}

.commentsList .media-left {
  padding-right: 25px;
}

.commentsList .media-left img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.commentsList .media-body {
  overflow: visible;
  padding: 0;
  position: relative;
}

.commentsList .media-body .media-heading {
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.commentsList .media-body p {
  font-size: 13px;
  margin-bottom: 20px;
}

.commentsList cite {
  display: block;
  font-size: 14px;
  color: #999;
  font-weight: normal;
  font-style: normal;
  margin-top: 9px;
}

.commentsList cite:before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 20px;
  height: 1px;
  background-color: #999;
  margin-right: 9px;
}

/*================= 8 COMING SOON ==================*/
/*=== 8.1 COMING SOON BG ===*/
.comingSoonBg {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80px8;
  overflow: hidden;
  background: no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #ffffff;
}

.comingTitle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 35px 0;
  text-align: center;
}

@media (min-width: 320px) {
  .comingTitle {
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .comingTitle {
    padding: 35px 0;
  }
}

.comingTitle img {
  width: 200px;
  height: 60px;
}

.comingContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (min-width: 320px) {
  .comingContent {
    width: 100%;
    min-width: 100%;
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .comingContent {
    width: auto;
    min-width: 557px;
    padding: 0;
  }
}

.comingContent h1 {
  font-weight: 700;
  color: #222222;
  font-size: 4px1;
  font-family: 'Dosis', sans-serif;
  text-transform: capitalize;
}

@media (min-width: 320px) {
  .comingContent h1 {
    font-size: 30px;
    margin: 20px 0 15px 0;
  }
}

@media (min-width: 768px) {
  .comingContent h1 {
    font-size: 40px;
    margin: 70px 0 25px 0;
  }
}

.comingContent p {
  color: #202020;
  margin-bottom: 20px;
}

@media (min-width: 320px) {
  .comingContent p {
    font-size: 10px;
    line-height: 15px;
  }
}

@media (min-width: 768px) {
  .comingContent p {
    font-size: 24px;
    line-height: 21px;
  }
}

.comingContent .input-group {
  margin: 0 auto;
  margin-bottom: 20px;
}

@media (min-width: 320px) {
  .comingContent .input-group {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .comingContent .input-group {
    width: 550px;
  }
}

.comingContent .input-group .form-control {
  height: 50px;
  box-shadow: none;
  background-color: transparent;
  border-color: rgba(165, 151, 231, 0.5);
  border-width: 3px;
  color: #222222;
  border-right: none;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
  width: 92%;
}

.comingContent .input-group .input-group-addon {
  height: 50px;
  background-color: transparent;
  color: #888888;
  border: 3px solid rgba(165, 151, 231, 0.5);
  border-left: none;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
  padding: 6px 10px;
  display: inline-block;
  float: left;
  width: 8%;
}

@media (min-width: 768px) {
  .comingContent .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .comingContent .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .comingContent .list-inline li {
    padding: 0 5px;
  }
}

.comingContent .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #ffffff !important;
}

.comingContent .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 320px) {
  .comingContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .comingContent .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .comingContent .list-inline li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}

.comingContent .list-inline li a i {
  margin: 0 !important;
}

.count-down {
  padding: 0 0 35px 0;
}

.count-down #simple_timer {
  width: 100%;
  margin: 0 auto;
  color: #f0c24b;
  text-align: center;
  font-family: 'Dosis', sans-serif;
}

.count-down #simple_timer .table-cell {
  display: inline-block;
}

@media (min-width: 480px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell {
    margin: 0 12px;
  }
}

.count-down #simple_timer .table-cell .tab-val {
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 320px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 120px;
    height: 120px;
    line-height: 120px;
    font-size: 70px;
  }
}

.count-down #simple_timer .table-cell .tab-metr {
  display: none;
}

.count-down #simple_timer .table-cell.hour .tab-val {
  color: #b5d56a;
}

.count-down #simple_timer .table-cell.minute .tab-val {
  color: #ea7066;
}

.count-down #simple_timer .table-cell.second .tab-val {
  color: #84bed6;
}

/*================= 9 SERVICES ==================*/
/*=== 9.1 SERVICES STYLE ===*/
.servicesStyle {
  margin-bottom: 40px;
}

.servicesStyle > .media-body {
  width: auto;
  display: inherit;
}

.servicesStyle > .media-body > .media-heading {
  text-transform: capitalize;
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.servicesStyle .media-left {
  width: 76px;
  height: 76px;
  padding: 6px;
  margin-right: 20px;
  display: inline-block;
  float: left;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.servicesStyle .media-left > i {
  width: 62px;
  height: 62px;
  line-height: 62px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #ffffff;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.servicesStyle .media-left:hover i {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.servicesStyle.alt .media-left > i {
  border: none;
}

.servicesStyle.alignRight .media-left {
  float: right;
  margin: 0 0 0 20px;
}

.servicesStyle.alignRight > .media-body h3, .servicesStyle.alignRight > .media-body p {
  text-align: right;
}

@media (min-width: 768px) {
  .servicesContent .media-body {
    display: block;
    margin-top: 20px;
    max-width: 198px;
    float: left;
  }
}

@media (min-width: 992px) {
  .servicesContent .media-body {
    margin-top: 0;
    display: table-cell;
    float: none;
  }
}

@media (min-width: 1200px) {
  .servicesContent .media-body {
    max-width: 10000px;
  }
}

/*================= 10 TESTIMONIAL ==================*/
.testimonialArea {
  padding-bottom: 0 !important;
}

.testimonial-grid {
  float: left;
  margin-top: 50px;
}

.testimonialContent {
  width: 100%;
  height: 250px;
  display: block;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 35px;
  margin-bottom: 80px;
}

.testimonialContent span.userSign {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #ffffff;
  border-width: 6px;
  border-style: solid;
  top: -50px;
  font-size: 30px;
  line-height: 90px;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -50px;
}

.testimonialContent p {
  color: #ffffff;
  margin: 25px 0;
  font-size: 16px;
}

.testimonialContent h3 {
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  font-size: 16px;
  line-height: 30px;
}

.testimonialContent h3 small {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
}

/*================= 11 CART PAGE ==================*/
.cartInfo .table-responsive .table {
  border-collapse: separate;
}

.cartInfo .table-responsive .table thead tr {
  border-left: 1px solid #f0c24b;
  border-right: 1px solid #f0c24b;
}

.cartInfo .table-responsive .table thead tr th {
  color: #ffffff;
  padding: 15px 8px;
  border-bottom: none;
  font-weight: 600;
  text-transform: uppercase;
}

.cartInfo .table-responsive .table thead tr th:first-child {
  -webkit-border-radius: 7px 0 0 0;
  -moz-border-radius: 7px 0 0 0;
  border-radius: 7px 0 0 0;
}

.cartInfo .table-responsive .table thead tr th:last-child {
  -webkit-border-radius: 0 7px 0 0;
  -moz-border-radius: 0 7px 0 0;
  border-radius: 0 7px 0 0;
}

.cartInfo .table-responsive .table tbody tr td {
  vertical-align: middle;
  color: #222222;
  border-top: none;
  border-bottom: 1px solid #dedede;
}

@media (min-width: 320px) {
  .cartInfo .table-responsive .table tbody tr td {
    padding: 10px;
  }
}

@media (min-width: 992px) {
  .cartInfo .table-responsive .table tbody tr td {
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .cartInfo .table-responsive .table tbody tr td {
    padding: 20px 8px 20px 30px;
  }
}

.cartInfo .table-responsive .table tbody tr td:first-child {
  border-left: 1px solid #dedede;
}

.cartInfo .table-responsive .table tbody tr td:last-child {
  border-right: 1px solid #dedede;
}

.cartInfo .table-responsive .table tbody tr td .close {
  display: inline-block;
  float: left;
  margin: 17px 30px 0 0;
  opacity: 1;
}

.cartInfo .table-responsive .table tbody tr td .close span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  background-color: #e5e5e5;
  color: #666666;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.cartInfo .table-responsive .table tbody tr td input {
  width: 80px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  color: #222222;
  border: 1px solid #dedede;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.cartInfo .table-responsive .table tbody tr td:last-child {
  padding-right: 30px;
}

.cartInfo .table-responsive .table tbody tr:last-child td:first-child {
  -webkit-border-radius: 0 0 0 7px;
  -moz-border-radius: 0 0 0 7px;
  border-radius: 0 0 0 7px;
}

.cartInfo .table-responsive .table tbody tr:last-child td:last-child {
  -webkit-border-radius: 0 0 7px 0;
  -moz-border-radius: 0 0 7px 0;
  border-radius: 0 0 7px 0;
}

.cartInfo .table-responsive .table tbody tr:last-child td:last-child.btnPart {
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.cartInfo .table-responsive .table .input-group .form-control {
  width: 257px;
  height: 45px;
  text-align: left;
  margin-right: 10px;
}

.cartInfo .table-responsive .table .input-group .input-group-addon {
  width: auto;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.cartInfo.cartTotal .table tbody tr td:last-child {
  padding: 25px;
  text-align: right;
}

/*================= 12 PRICING TABLE ==================*/
.pricingTable {
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin-bottom: 30px;
}

.pricingTable .priceUper, .pricingTable .priceLower {
  display: block;
  width: 100%;
}

.pricingTable .priceLower {
  padding: 50px 20px 30px 20px;
}

.pricingTable .priceLower .priceBtn .btn-primary {
  border: none !important;
}

.pricingTable.tableVertical {
  width: 100%;
  float: left;
}

@media (min-width: 480px) {
  .pricingTable.tableVertical .priceUper, .pricingTable.tableVertical .priceLower {
    display: inline-block;
    float: left;
    width: 50%;
  }
}

.priceTitle {
  height: auto;
  padding: 35px 20px 10px 20px;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

.priceTitle h3 {
  margin: 0;
  color: #ffffff;
  font-family: 'Dosis', sans-serif;
  font-size: 34px;
  font-weight: 600;
  text-transform: capitalize;
}

.priceTitle.alt {
  height: 90px;
  padding: 0 20px;
}

.priceTitle.alt h3 {
  line-height: 90px;
  font-size: 36px;
}

.priceTitle.checkingTitle {
  height: 80px;
  padding: 0 20px;
}

.priceTitle.checkingTitle h3 {
  line-height: 80px;
  font-size: 24px;
}

.priceInfo {
  height: 104px;
}

.priceInfo p {
  color: #fffefe;
  padding: 0 20px;
}

.priceInfo.alt {
  height: 180px;
  overflow: hidden;
}

.priceOffer li {
  margin-bottom: 25px;
  font-family: 'Dosis', sans-serif;
  color: #666666;
  font-size: 18px;
}

.priceOffer li i {
  margin-right: 8px;
}

.priceOffer li:last-child {
  margin-bottom: 35px;
}

.priceBtn h4 {
  display: inline-block;
  float: right;
  font-family: 'Dosis', sans-serif;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
  line-height: 20px;
}

.priceBtn h4 small {
  text-transform: capitalize;
  font-size: 14px;
}

.priceBtn h5 {
  font-family: 'Dosis', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #222222;
}

.priceBtn h5 span {
  color: #ea7066;
}

.tableVertical .priceTitle {
  -webkit-border-radius: 8px 0 0 0;
  -moz-border-radius: 8px 0 0 0;
  border-radius: 8px 0 0 0;
}

.tableVertical .priceInfo {
  min-height: 205px;
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}

@media (min-width: 768px) {
  .tableVertical .priceInfo {
    min-height: 135px;
  }
}

.tableVertical .priceBtn.alt {
  padding: 20px 20px 0 20px;
}

.tableVertical .priceBtn.alt .btn-primary {
  background-color: #ffffff;
  color: #666666;
}

.tableVertical .priceBtn.alt h4 {
  color: #ffffff;
}

.tableVertical .priceBtn.alt h4 small {
  color: #ffffff;
}

@media (min-width: 480px) {
  .tableVertical .priceBtn.alt h4 {
    float: none;
    display: block;
    margin-top: 20px;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .tableVertical .priceBtn.alt h4 {
    float: right;
    display: inline-block;
    margin-top: 0;
    text-align: center;
  }
}

.tableVertical .priceLower {
  padding: 20px 20px 0 20px;
}

/*================= 13. CHECKOUT STEP ==================*/
/*========== 13.1 CHECKOUT PROGRESS ==========*/
.progress-wizard {
  margin-top: 40px;
  margin: 40px 0 70px 0;
  padding: 0 0 30px 0;
}

.progress-wizard > .progress-wizard-step {
  padding: 0;
  position: relative;
  height: 105px;
}

@media (min-width: 320px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 0;
    height: 100px;
  }
}

.progress-wizard > .progress-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 5px;
  box-shadow: none;
  margin: 20px 0;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
}

@media (min-width: 320px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background: #ea7066;
  }
}

.progress-wizard > .progress-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.progress-wizard > .progress-wizard-step:last-child > .progress {
  width: 50%;
}

.progress-wizard > .progress-wizard-step.disabled a.progress-wizard-dot {
  pointer-events: none;
}

@media (min-width: 320px) {
  .progress {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .progress {
    background-color: #f8f8f8;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot {
  display: block;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 5px solid #ea7066;
  padding: 5px;
  text-align: center;
  color: #000000;
}

@media (min-width: 320px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    width: 110px;
    position: relative;
    top: 0;
    left: 0;
    margin: -20px auto 0 auto;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    width: auto;
    position: absolute;
    top: -30px;
    left: 28%;
    margin-top: -15px;
    margin-left: -15px;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot i {
  display: block;
  color: #ea7066;
  font-size: 24px;
  line-height: 90px;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #ededed;
  text-align: center;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot i {
    line-height: 102px;
    width: 102px;
    height: 102px;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.progress-wizard > .progress-wizard-step.incomplete > .progress-wizard-dot {
  border-color: #f8f8f8;
  color: #666666;
}

.progress-wizard > .progress-wizard-step.incomplete > .progress-wizard-dot i {
  color: #666666;
}

.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot i {
  color: #ffffff;
  background-color: #ea7066;
}

.progress-wizard .progressInfo {
  text-align: center;
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #222222;
}

@media (min-width: 768px) {
  .progress-wizard .progressInfo {
    margin: 70px 0 0 0px;
  }
}

@media (min-width: 992px) {
  .progress-wizard .progressInfo {
    margin: 70px 0 0 -40px;
  }
}

@media (min-width: 1200px) {
  .progress-wizard .progressInfo {
    margin: 70px 0 0 -70px;
  }
}

/*========== 13.2 CHECKOUT INFO ==========*/
.checkoutInfo.panel {
  border: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.checkoutInfo .panel-heading {
  height: 80px;
  padding: 0 30px;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

.checkoutInfo .panel-heading .panel-title {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  line-height: 80px;
  text-transform: capitalize;
}

.checkoutInfo .panel-body {
  border: 1px solid #dedede;
  border-top: none;
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
  padding: 50px 30px;
}

.checkoutInfo .form-group label {
  color: #222222;
  font-weight: 400;
}

.checkoutInfo .form-group .form-control {
  height: 45px;
  border-width: 3px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.checkoutInfo .form-group textarea.form-control {
  height: 151px;
}

.checkoutInfo .checkbox label {
  color: #222222;
}

.checkoutInfo .checkbox a:hover {
  color: #ea7066;
}

.panel-body .form-group {
  display: inline;
}

.continueBtnArea .btn-primary {
  background-color: #f8f8f8 !important;
  border: none !important;
  color: #666666;
}

.continueBtnArea .btn-primary:hover {
  background-color: #ea7066 !important;
  color: #ffffff !important;
}

.lowerPartTitle h4 {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ea7066;
  text-transform: capitalize;
  margin-top: 15px;
  margin-left: 15px;
}

.lowerPartTitle h4 span {
  color: #222222;
}

.lowerPartTitle p {
  margin-bottom: 30px;
  color: #222222;
}

.blankLabel {
  height: 16px;
}

.addressInfo dt {
  width: 70px;
  text-align: left;
  font-weight: 600;
  color: #222222;
}

.addressInfo dd {
  margin-left: 70px;
  color: #222222;
}

.card-img {
  text-align: center;
}

.card-img img {
  display: inline-block;
  margin-bottom: 7px;
}

@media (min-width: 992px) {
  .categorySection {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .categorySection .box {
    margin-bottom: 100px;
  }
}

.categorySection .box-info h4 {
  margin-bottom: 0;
}

.categorySection .box-info {
  padding: 25px;
}

/*=== 14.1 PRODUCT GALLERY ===*/
.product-gallery {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .product-gallery {
    margin-bottom: 0;
  }
}

.product-gallery .product-gallery-preview {
  display: block;
  position: relative;
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.product-gallery .product-gallery-preview li {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 0 0 117px;
  opacity: 0;
  padding: 70px 0;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .product-gallery .product-gallery-preview li {
    margin: 0 0 0 87px;
  }
}

.product-gallery .product-gallery-preview li img {
  display: inline-block;
}

.product-gallery .product-gallery-preview li.current {
  position: relative;
  opacity: 1;
}

.product-gallery .product-gallery-thumblist {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  list-style: none;
  padding: 0;
}

@media (max-width: 480px) {
  .product-gallery .product-gallery-thumblist {
    width: 80px;
  }
}

.product-gallery .product-gallery-thumblist li {
  display: block;
  padding: 15px 10px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 7px;
}

@media (max-width: 480px) {
  .product-gallery .product-gallery-thumblist li {
    padding: 10px 7px;
  }
}

.product-gallery .product-gallery-thumblist li a {
  display: block;
  text-decoration: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0.3);
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  opacity: 0.3;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.product-gallery .product-gallery-thumblist li.active > a {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.product-info .product-title {
  font-family: 'Dosis', sans-serif;
  font-size: 40px;
  color: #222222;
  text-transform: capitalize;
  margin-bottom: 50px;
  font-weight: 600;
}

.product-info .price span {
  font-size: 55px;
  color: #84bed6;
  margin-bottom: 45px;
}

.product-info .price span del {
  color: #e4e4e4;
  font-size: 45px;
  margin-left: 15px;
}

.product-info p {
  font-size: 15px;
  margin-bottom: 45px;
}

.add-cart .count-input {
  display: inline-block;
  position: relative;
  width: 70px;
  margin-right: 10px;
}

.add-cart .count-input .btn-primary {
  width: 100%;
  background: #f8f8f8 !important;
  border: none !important;
  color: #666666;
  position: relative;
  width: 100%;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.add-cart .count-input .incr-btn {
  position: absolute;
  right: 7px;
  color: #666666;
  height: 14px;
  display: inline-block;
  line-height: 10px;
}

.add-cart .count-input .incr-up {
  top: 8px;
}

.add-cart .count-input .incr-down {
  bottom: 9px;
}

.product-category {
  color: #222222;
  font-size: 15px;
  margin: 45px 0;
}

.share span {
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  color: #222222;
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
}

.share .list-inline {
  display: inline-block;
}

.share .list-inline li {
  padding: 2px;
}

.share .list-inline li a {
  display: block;
  text-align: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
}

.share .list-inline li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

/*=== 14.2 PRODUCT REVIEW ===*/
.product-review .tabCommon .nav {
  text-align: left;
}

.product-review .tabCommon .tab-content {
  padding-top: 25px;
  padding-bottom: 5px;
}

.product-review .tabCommon .tab-pane {
  border: none;
  background: #f8f8f8;
  padding: 30px 25px;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.product-review .tab-content h3 {
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  color: #ea7066;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-weight: 600;
}

.product-review .tab-content p {
  font-size: 15px;
  margin-bottom: 25px;
  color: #666666;
}

.product-review .para-list.list-unstyled {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 30px;
}

.product-review .commentsList .media-body .media-heading {
  text-transform: uppercase;
}

.product-review .product-rating {
  display: inline-block;
  color: #ffce2b;
  position: absolute;
  right: 0;
  top: 0;
}
