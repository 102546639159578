/*================= 11 CART PAGE ==================*/
.cartInfo{
	.table-responsive{
		.table{
			border-collapse: separate;
			thead{
				tr{
					@include add-border(1px, $color1, right-left);
					th{
						color: $white-color;
						padding: 15px 8px;
						border-bottom: none;
						font-weight: 600;
						text-transform: uppercase;
						&:first-child{
							@include border-radius(7px 0 0 0);
						}
						&:last-child{
							@include border-radius( 0 7px 0 0);
						}
					}
				}
			}
			tbody{
				tr{
					td{
						vertical-align: middle;
						color: #222222;
						border-top: none;
						@include add-border(1px, #dedede, bottom);
						@include mobile-all{
							padding: 10px;
						}
						@include desktops{
							padding: 10px;
						}
						@include large-desktops{
							padding: 20px 8px 20px 30px;
						}
						&:first-child{
							@include add-border(1px, #dedede, left);
						}
						&:last-child{
							@include add-border(1px, #dedede, right);
						}
						.close{
							display: inline-block;
							float: left;
							margin: 17px 30px 0 0;
							opacity: 1;
							span{
								width: 30px;
								height: 30px;
								line-height: 30px;
								display: block;
								background-color: #e5e5e5;
								color: $text-color;
								@include border-radius(100%);
							}
						}
						input {
							width: 80px;
							height: 45px;
							text-align: center;
							line-height: 45px;
							color: #222222;
							@include add-border(1px, #dedede, all);
							@include border-radius(7px);
						}
						&:last-child{
							padding-right: 30px;
						}
					}
					&:last-child{
						td{
							&:first-child{
								@include border-radius( 0 0 0 7px);
							}
							&:last-child{
								@include border-radius( 0 0 7px 0);
								&.btnPart{
									@include border-radius( 0 0 7px 7px);
								}
							}
						}
					}
				}
			}
			.input-group{
				.form-control{
					width: 257px;
					height: 45px;
					text-align: left;
					margin-right: 10px;
				}
				.input-group-addon{
					width: auto;
					@include border-radius(7px);
				}
			}
		}
	}
	&.cartTotal{
		.table{
			tbody{
				tr{
					td{
						&:last-child{
							padding: 25px;
							text-align: right;
						}
					}
				}
			}
		}
	}
}