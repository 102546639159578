/*=== MIXINS ===*/
@mixin box-sizing($box) {
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}
@mixin border-radius($radius: 4px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}
@mixin opacity ( $value: 0.5 ) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=$value);
  -moz-opacity: $value;
  -khtml-opacity: $value; 
  opacity: $value;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
@mixin add-border($size, $color, $position){
  @if $position == 'all'{
    border:$size solid $color;
  } @else if $position == 'top'{
    border-top:$size solid $color;
  } @else if $position == 'bottom'{
    border-bottom:$size solid $color;
  } @else if $position == 'right'{
    border-right:$size solid $color;
  } @else if $position == 'left'{
    border-left:$size solid $color;
  } @else if $position == 'top-bottom'{
    border-top:$size solid $color;
    border-bottom:$size solid $color;
  } @else if $position == 'right-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'top-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'top-right'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'bottom-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'bottom-right'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  }
}
@mixin background($imgpath,$position:0 0, $size:cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: no-repeat;
    size: $size;
  }
}
@mixin size($width, $height, $bg-color) {
  width: $width;
  height: $height;
  background-color: $bg-color;
}
@mixin add-padding($size, $position){
  @if $position == 'all'{
    padding:$size; 
  } @else if $position == 'top'{
    padding-top:$size;
  } @else if $position == 'bottom'{
    padding-bottom:$size;
  } @else if $position == 'right'{
    padding-right:$size;
  } @else if $position == 'left'{
    padding-left:$size;
  } @else if $position == 'top-bottom'{
    padding-top:$size;
    padding-bottom:$size;
  } @else if $position == 'left-right'{
    padding-left:$size; 
    padding-right:$size; 
  } 
}
@mixin add-custom-padding($size-top, $size-right, $size-bottom, $size-left){
  padding: $size-top $size-right $size-bottom $size-left; 
}

@mixin background($imgpath,$position:0 0, $size:cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: no-repeat;
    size: $size;
  }
}